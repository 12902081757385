import React from "react";
import AppTable from "AdminApp/components/crud/AppTable";
import { Link } from "react-router-dom";
import AppBadge from "AdminApp/components/typography/AppBadge";
import NumberHelper from "AdminApp/logic/helpers/NumberHelper";
import UserGroupUserModel from "../models/UserGroupUserModel";

export default class GroupUsers extends React.Component {
  render() {
    let group = this.props.group;

    return (
      <AppTable
        parseUrl={false}
        model={UserGroupUserModel}
        onInit={(ref) => {
          this._ref = ref;
          this.props.onInit(ref);
        }}
        params={{
          id: group.id,
        }}
        renderCell={(column, item, index) => {
          switch (column.name) {
            case "status":
              return <AppBadge text={item.status} />;
            case "amount":
              return NumberHelper.currency(item.amount, item.currency);
          }
        }}
        getColumnActions={(item, index) => {
          return (
            <div>
              <Link
                to={`/users/all-users/details/${item.id}`}
                className="button-info"
              >
                Details
              </Link>
            </div>
          );
        }}
      />
    );
  }
}

GroupUsers.defaultProps = {
  onInit: () => {},
};
