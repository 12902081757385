import BaseModel from '../../models/BaseModel';

export default class LoginModel extends BaseModel {

    getSubmitUrl() {
        return 'member/login';
    }
     /**
     * Get validation rules
     */
  getValidationRules() {
    return {
      email: {
        presence: {
          allowEmpty: false,
          message: '^Please enter an email address'
        },
        email: {
          message: '^Please enter a valid email address'
        }
      },
      password: {
        presence: {
          allowEmpty: false,
          message: '^Please enter password'
        }
      }
    }
  }
}
