import React from 'react';



export default class PushDetails extends React.Component {
  render() {
    return (

      <div className="credit-card-management">
        <div className="activated-cards">
          <div className="dark-blur"></div>
          <div className="box-header">
            <h2><i className="fa fa-bell"></i> Push Notification Details </h2>
          </div>
          <div className="row" style={{ paddingBottom: 40, paddingTop: 40 }}>
          <div className="col-xl-6">
              <div className="push" ref="element">
                <form action="">
                  <label htmlFor="title" style={{ color: 'white' }}>Push Notification Title</label>
                  <input type="text" value="Sit back, enjoy your" />
                  <label htmlFor="title" style={{ color: 'white' }}>Notification type</label>
                  <input type="text" value="Promotional" />
                  <label htmlFor="title" style={{ color: 'white' }}>Notification group</label>
                  <input type="text" value="Group 1" />
                  <label htmlFor="title" style={{ color: 'white' }}>Date sent</label>
                  <input type="text" value="02/08/2017" />
                  <label htmlFor="title" style={{ color: 'white' }}>Notification image</label>
                  <img src="/img/push-image.png" style={{maxWidth: '100%'}}/>
                  <label htmlFor="">Notification Message</label>
                  <textarea name="" id="" >Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</textarea>
                </form>
              </div>
            </div>
            <div className="col-xl-6">
              <img src="/img/push-notification.png" alt="" style={{ maxHeight: '600px', display: 'block', margin: 'auto' }} />
            </div>

          </div>
        </div>
      </div>
    );
  }
}
