import React, { Component } from "react";
import { Link } from "react-router-dom";
import SubscribersWidget from "./components/SubscribersWidget";
import SubscribersTrend from "./components/SubscribersTrend";
import MessagesSentChart from "./components/MessagesSentChart";
import SendNotificationModel from "./models/SendNotificationModel";
import DevicesModel from "../users/user-devices/models/DevicesModel";
import AppBadge from "AdminApp/components/typography/AppBadge";
import AppTable from "AdminApp/components/crud/AppTable";
import DateHelper from "AdminApp/logic/helpers/DateHelper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};
class PushDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let disabledActionUser =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_ADMIN_TESTER");
    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-tachometer" aria-hidden="true"></i> Push Dashboard
        </h1>

        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <SubscribersWidget disabledActionUser={disabledActionUser} />
          <SubscribersTrend />
          <MessagesSentChart />
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="activated-cards">
              <div className="dark-blur"></div>
              <div className="box-header">
                <h2>
                  <i className="fa fa-users"></i> Latest Subscribers
                </h2>
                <div className="box-header-action">
                  <Link to="/users/user-devices" className="button">
                    List all
                  </Link>
                </div>
              </div>
              <div className="box-body">
                <AppTable
                  title={null}
                  onInit={(tableComponent) =>
                    (this._tableComponent = tableComponent)
                  }
                  filter={null}
                  paginate={false}
                  params={{
                    limit: 10,
                  }}
                  parseUrl={false}
                  order={[
                    {
                      field: "id",
                      direction: "desc",
                    },
                  ]}
                  model={DevicesModel}
                  getColumnActions={(member, index) => {
                    return (
                      <div>
                        <Link
                          to={`/users/user-devices/details/${member.id}`}
                          className="button-info"
                        >
                          Details
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="activated-cards">
              <div className="dark-blur"></div>
              <div className="box-header">
                <h2>
                  <i className="fa fa-comments" /> Latest Pushes
                </h2>
                <div className="box-header-action">
                  <Link
                    to="/push-notifications/sent-notifications"
                    className="button"
                  >
                    List all
                  </Link>
                </div>
              </div>
              <div className="box-body">
                <AppTable
                  title={null}
                  onInit={(tableComponent) =>
                    (this._tableComponent = tableComponent)
                  }
                  filter={null}
                  paginate={false}
                  params={{
                    limit: 10,
                  }}
                  parseUrl={false}
                  order={[
                    {
                      field: "id",
                      direction: "desc",
                    },
                  ]}
                  model={SendNotificationModel}
                  renderCell={(column, item, index) => {
                    switch (column.name) {
                      case "status":
                        return <AppBadge text={item.status} />;
                      case "created":
                        return DateHelper.date(item.created, "D / MMMM / Y");
                      case "text":
                        return (
                          <p
                            style={{
                              whiteSpace: "nowrap",
                              width: 100,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.text}
                          </p>
                        );
                      default:
                        return undefined;
                    }
                  }}
                  getColumnActions={(sendNotification, index) => {
                    return (
                      <div>
                        <Link
                          to={`/push-notifications/details/${sendNotification.id}`}
                          className="button-info"
                        >
                          Details
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(PushDashboard);
