import { all, takeEvery, fork, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  MEMBER_LOGIN_TRIGGER,
  // MEMBER_LOGIN_TRIGGER,
  // MEMBER_LOGOUT,
  MEMBER_AUTH_REFRESH,
  MEMBER_LOGOUT_TRIGGER,
  MEMBER_TOKEN_LOADED,
  MEMBER_AUTH_LOADED,
  SET_CHAT_TOTAL_UNREAD_MESSAGES,
  // MEMBER_PULL_INFO_TRIGGER,
  // MEMBER_INFO_UPDATED,
  // MEMBER_CHECK_AUTH_TRIGGER,
  // MEMBER_REGISTRATION_SUCCESS_TRIGGER,
  // MEMBER_AUTH_STORE_INFO,
  // MEMBER_AUTH_REFRESH,
  // MEMBER_AUTH_REFRESH_FROM_DB,
  // MEMBER_AUTH_REFRESH_FROM_NETWORK,
  // memberInfoUpdatedAction,
  // storeAuthInfoAction,
  // refreshAuthFromDbAction,
  // updateAccountInStore
} from "./AuthActions";
import Api from "AdminApp/common/script/services/Api";

// /**
//  * Logout user
//  *
//  * @param {Object} action
//  */
// function* loginMember(action) {
//   try {
//     window.localStorage.setItem('AUTH_TOKEN', action.payload.token);
//   } catch(e) {
//   }
// }
// function* logoutMember(action) {

//   try {
//     window.localStorage.removeItem('AUTH_TOKEN', action.payload.token);
//   } catch(e) {
//   }
// }

// export function* watchMemberLogin() {
//   yield takeEvery(MEMBER_LOGIN_TRIGGER, loginMember);
// }
// export function* watchMemberLogout() {
//   yield takeEvery(MEMBER_LOGOUT_TRIGGER, logoutMember);
// }

// export default function* authSagas() {
//   yield all([
//     fork(watchMemberLogin),
//     fork(watchMemberLogout)
//   ]);
// }

/**
 * Logout user
 *
 * @param {Object} action
 */
function* loginMember(action) {
  try {
    window.localStorage.setItem("AUTH_TOKEN", action.payload.token);

    yield put({ type: MEMBER_AUTH_LOADED, payload: action.payload.account });
    yield put({ type: SET_CHAT_TOTAL_UNREAD_MESSAGES, payload: { total: 0 } });
  } catch (e) {}
}
function* logoutMember(action) {
  try {
    window.localStorage.removeItem("AUTH_TOKEN", action.payload.token);
  } catch (e) {}
}

function* refreshAuth(action) {
  try {
    let token = window.localStorage.getItem("AUTH_TOKEN");

    if (token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
      let result = yield Api.call("member/me");

      if (result.success) {
        yield put({ type: MEMBER_AUTH_LOADED, payload: result.data });
        yield put({
          type: SET_CHAT_TOTAL_UNREAD_MESSAGES,
          payload: { total: 0 },
        });
      }
    }
  } catch (e) {}
}

export function* watchMemberLogin() {
  yield takeEvery(MEMBER_LOGIN_TRIGGER, loginMember);
}
export function* watchMemberLogout() {
  yield takeEvery(MEMBER_LOGOUT_TRIGGER, logoutMember);
}
export function* watchRefreshAuth() {
  yield takeEvery(MEMBER_AUTH_REFRESH, refreshAuth);
}

export default function* authSagas() {
  yield all([
    fork(watchMemberLogin),
    fork(watchMemberLogout),
    fork(watchRefreshAuth),
  ]);
}
