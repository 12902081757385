import React, { Component } from "react";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";
import UserGroupForm from "./components/UserGroupForm";
import AppFetcher from "AdminApp/components/crud/AppFetcher";
import AppPage from "AdminApp/layouts/AppPage";
import AppWidget from "AdminApp/layouts/AppWidget";

export default class UserGroupEdit extends Component {
  render() {
    return (
      <AppPage title={"Edit group"} icon={"pencil"}>
        <AppFetcher
          model={NotificationUserGroupModel}
          id={this.props.match.params.id}
          renderContent={(record) => (
            <AppWidget title={record.name} icon={null}>
              <UserGroupForm record={record} />
            </AppWidget>
          )}
        ></AppFetcher>
      </AppPage>
    );
  }
}
