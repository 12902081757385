import BaseModel from "AdminApp/common/script/models/BaseModel";

export default class SendNotificationModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return "notifications/send";
  }

  /**
   * Get list usrl
   */
  getListUrl() {
    return "notifications/send-request/list";
  }

  getDetailsUrl() {
    return "notifications/send-request/details";
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: true,
        type: "field",
      },
      {
        name: "name",
        label: "Name",
        sortable: true,
        type: "field",
      },
      {
        name: "title",
        label: "Title",
        sortable: false,
        type: "field",
      },
      {
        name: "text",
        label: "Text",
        sortable: false,
        type: "field",
      },
      {
        name: "userCount",
        label: "User count",
        sortable: false,
        type: "field",
      },
      {
        name: "status",
        label: "Status",
        sortable: false,
        type: "field",
      },
      {
        name: "created",
        label: "Sent",
        sortable: false,
        type: "field",
      },
      {
        name: null,
        label: "Actions",
        sortable: false,
        type: "actions",
      },
    ];
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      name: {
        presence: {
          allowEmpty: false,
          message: "^Please enter name",
        },
      },
      title: {
        presence: {
          allowEmpty: false,
          message: "^Please enter notification title",
        },
      },
      text: {
        presence: {
          allowEmpty: false,
          message: "^Please enter notification text",
        },
      },
    };
  }
}
