import React, { Component } from "react";
import UserGroupForm from "./components/UserGroupForm";
import AppPage from "AdminApp/layouts/AppPage";
import AppWidget from "AdminApp/layouts/AppWidget";

export default class AddUserGroup extends Component {
  render() {
    return (
      <AppPage title={"Notification groups"} icon={"plus"}>
        <AppWidget title={"Add group"} icon={null}>
          <UserGroupForm />
        </AppWidget>
      </AppPage>
    );
  }
}
