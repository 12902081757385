import React from "react";
import { withRouter } from "react-router";
import qs from "qs";
import LoadingOverlay from "AdminApp/common/script/components/LoadingOverlay";
import _ from "lodash";
import AppTableFilter from "./table/AppTableFilter";
import AppTableHead from "./table/AppTableHead";
import AppTablePagination from "./table/AppTablePagination";
import AppIcon from "../typography/AppIcon";
import Config from "AdminApp/config";

class AppTable extends React.Component {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      items: [],
      filter: this._getInitialFilter(props),
      order: this._getInitialOrder(props),
      pagination: {
        page: this._getInitialPage(props),
        total_pages: 0,
        limit: 20,
        total_items: 0,
      },
      loadedTimes: 0,
      isScrolled: false,
      isHovered: false,
    };
    this.tableContainerRef = React.createRef();
    props.onInit(this);
  }

  getFilter() {
    return this.state.filter;
  }

  _getInitialFilter(props) {
    if (props.location.query.filter) {
      return this._joinFilters(props.filter, props.location.query.filter);
    }

    return props.filter;
  }

  _joinFilters(filter, otherFilters) {
    let mainFilter = [...filter];

    otherFilters.forEach((otherFilter) => {
      let existingFilter = mainFilter.find((x) => x.name === otherFilter.name);
      if (existingFilter) {
        if (otherFilter.value === Config.initFilterValue) {
          mainFilter = mainFilter.filter((x) => x.name !== otherFilter.name);
        } else {
          let indexOf = mainFilter.indexOf(existingFilter);
          mainFilter[indexOf] = otherFilter;
          // mainFilter[indexOf] = {...existingFilter, ...otherFilter};
        }
      } else {
        if (otherFilter.value !== Config.initFilterValue) {
          mainFilter.push(otherFilter);
        }
      }
    });
    return mainFilter;
  }

  _getInitialOrder(props) {
    if (props.location.query.order) {
      return props.location.query.order;
    }
    return props.order || [];
  }

  /**
   * Called on mount
   */
  componentDidMount() {
    this._loadItems();
    if (this.tableContainerRef.current) {
      this.tableContainerRef.current.addEventListener(
        "scroll",
        this.handleScroll
      );
      this.tableContainerRef.current.addEventListener(
        "mouseenter",
        this.handleMouseEnter
      );
    }
  }

  componentWillUnmount() {
    if (this.tableContainerRef.current) {
      this.tableContainerRef.current.removeEventListener(
        "scroll",
        this.handleScroll
      );
      this.tableContainerRef.current.removeEventListener(
        "mouseenter",
        this.handleMouseEnter
      );
    }
  }

  /**
   * Loading off
   */
  _loadingOn() {
    this.setState({
      loading: true,
    });
  }

  /**
   * Loading on
   */
  _loadingOff() {
    this.setState({
      loading: false,
    });
  }

  /**
   * Get initial page
   *
   * @param {Object} props Passed props
   */
  _getInitialPage(props) {
    if (!props.parseUrl) return 1;
    return props.location.query.page ? Number(props.location.query.page) : 1;
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }

  /**
   * Load items
   */
  async _loadItems() {
    // Show loader
    this._loadingOn();

    try {
      let listParams = this._getListParams();
      let results = await this._getModel().fetch(listParams);

      this.setState(
        {
          loadedTimes: ++this.state.loadedTimes,
          items: results.items,
          pagination: { ...this.state.pagination, ...results.pagination },
        },
        () => {
          this._onLoaded();
        }
      );
    } catch (e) {
      console.error("Error while loading list from API: ", e);
    }

    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  _onLoaded() {
    this.props.onLoad(this.state);
  }

  async _refresh(force = true) {
    if (force && this.props.parseUrl) {
      let pageUrl =
        "?" +
        qs.stringify({
          ...this.props.location.query,
          page: this.state.pagination.page,
          filter: this.state.filter.filter((x) => x.value && x.value !== ""),
          order: this.state.order,
        });

      window.history.pushState("", "", pageUrl);
      this._loadItems();
      // history.push({
      //   search: qs.stringify({
      //     ...this.props.location.query,
      //     page: this.state.pagination.page,
      //     filter: this.state.filter,
      //     order: this.state.order
      //   })
      // })
    } else {
      window.scrollTo(0, 0);
      this._loadItems();
    }
  }

  _getListParams() {
    let additionalParams = {};

    if (this.state.filter) {
      additionalParams.filter = this.state.filter;
      // additionalParams.filter = this.state.filter.filter(x => x.value && (x.value + "".length > 0));
    }

    if (this.state.order) {
      additionalParams.order = this.state.order;
    }

    return {
      offset: (this.state.pagination.page - 1) * this.state.pagination.limit,
      limit: this.state.pagination.limit,
      ...additionalParams,
      ...this.props.params,
    };
  }

  /**
   * Called on page changed
   *
   * @param {Integer} pageNumber New page number
   */
  _pageChanged(pageNumber) {
    let pagination = { ...this.state.pagination };
    pagination.page = Number(pageNumber);
    this.setState(
      {
        pagination,
      },
      () => {
        this._refresh();
        if (this.tableContainerRef.current) {
          this.tableContainerRef.current.scrollTo(0, 0);
        }
      }
    );
  }

  /**
   * Get rows
   */
  _getRows() {
    let columns = this._getColumns();

    if (this.state.loadedTimes > 0 && this.state.items.length === 0) {
      return (
        <tr className={"app-table-tr " + this.props.getRowClass()}>
          <td colSpan={columns.length}>No records</td>
        </tr>
      );
    }

    if (this.state.loadedTimes === 0 && this.state.loading) {
      return (
        <tr className={"app-table-tr " + this.props.getRowClass()}>
          <td colSpan={columns.length}>
            <LoadingOverlay />
          </td>
        </tr>
      );
    }

    let elements = [];

    this.state.items.forEach((item, index) => {
      let innerCells = [];
      columns.forEach((column) => {
        innerCells.push(this._renderCell(column, item, index));
      });
      elements.push(
        <tr
          key={"tr" + index}
          className={"app-table-tr " + this.props.getRowClass(item, index)}
        >
          {innerCells}
        </tr>
      );
    });
    return elements;
  }

  _notRenderCell(column, item, index) {
    let notCell = this.props.notRenderCell(column, item, index);

    return notCell;
  }

  /**
   * Render single cell
   *
   * @param {Object} column Column which cell is rendered
   * @param {Object} item
   * @param {Integer} index
   */
  _renderCell(column, item, index) {
    if (column.type === "actions") {
      return this._renderActionsCell(item, index);
    }

    // Extract
    let cellContent = _.get(item, column.name);

    let overridenContent = this.props.renderCell(column, item, index);

    if (overridenContent || overridenContent === null) {
      cellContent = overridenContent;
    }

    let notContent = this._notRenderCell(column, item, index);

    if (notContent) {
      return null;
    }

    return (
      <td
        key={column.name + index}
        className={
          "app-table-td " + this.props.getCellClass(column, item, index)
        }
      >
        {cellContent}
      </td>
    );
  }

  /**
   * Render actions cell
   * @param column
   * @param item
   * @param index
   */
  _renderActionsCell(item, index) {
    let actions = this.props.getColumnActions(item, index);
    return (
      <td
        key={"td" + index}
        className={"app-table-td " + this.props.getCellClass(null, item, index)}
        align="right"
      >
        {actions}
      </td>
    );
  }

  /**
   * Get table header
   */
  _getTableHeader() {
    return (
      <AppTableHead
        columns={this._getColumns()}
        order={this.state.order}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
        onOrder={(order) => {
          this.setState(
            {
              order,
            },
            () => {
              this._refresh();
            }
          );
        }}
      />
    );
  }

  /**
   * Get columns for table
   */
  _getColumns() {
    let columns = this._getModel().getTableColumns();
    let overridenColumns = this.props.getTableColumns();
    if (overridenColumns.length) columns = overridenColumns;
    let disableColumn = this.props.disableColumn();

    if (disableColumn.length > 0) {
      let disableColumns = columns.filter((firstArrayItem) => {
        return disableColumn.find((secondArrayItem) => {
          return firstArrayItem.label === secondArrayItem;
        });
      });
      return disableColumns;
    }

    return columns;
  }

  _filter(filter) {
    this.setState(
      {
        filter: this._joinFilters(this.state.filter, filter),
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this._refresh();
      }
    );
  }

  _getTableTitle() {
    let { icon, title, headerActions } = this.props;

    if (!title) {
      return null;
    }

    let iconElement = <AppIcon icon={icon} />;

    return (
      <div>
        <div className="dark-blur"></div>
        <div className="box-header">
          <h2>
            {iconElement} {title}
          </h2>
          <div className="header-actions">
            {typeof headerActions === "function"
              ? headerActions()
              : headerActions}
          </div>
        </div>
      </div>
    );
  }

  _getLoadingElement() {
    if (!this.state.loading || this.state.loadedTimes === 0) return null;

    return <LoadingOverlay />;
  }

  handleScroll = () => {
    const container = this.tableContainerRef.current;
    if (container) {
      this.setState({ isScrolled: true });
    }
  };

  handleMouseEnter = () => {
    setTimeout(() => {
      this.setState({ isHovered: true });
    }, 1000);
  };

  /**
   * Render the component
   */
  render() {
    let tableHeader = this._getTableHeader();
    let rows = this._getRows();
    let title = this._getTableTitle();
    let loadingElement = this._getLoadingElement();
    const { isScrolled, isHovered } = this.state;
    let height = window.innerHeight;

    let tableContainer = document.getElementById("table-container");
    let table = document.getElementById("table");
    let hs = false;
    if (tableContainer) {
      hs = tableContainer.scrollWidth > tableContainer.clientWidth;
    }
    if (table) {
      if (hs && !isScrolled && !isHovered) {
        table.classList.add("moveAndBackOnHover");
      } else {
        table.classList.remove("moveAndBackOnHover");
      }
    }

    let otherElementsHeight = 345;

    return (
      <div className="element-with-blur">
        {title}
        {loadingElement}
        <div className="box-body">
          <AppTableFilter
            {...{ ...this.props, onInit: () => {} }}
            onFilter={(filter) => this._filter(filter)}
            padded
            sendFilterHeight={this.getFilterHeight}
          />
          <div
            id="table-container"
            ref={this.props.forwardedRef || this.tableContainerRef}
            style={{
              overflow: "auto",
              maxHeight: height - otherElementsHeight,
              marginBottom: 20,
              position: "relative",
            }}
          >
            <table className="table" id="table">
              <tbody>
                {tableHeader}
                {rows}
              </tbody>
            </table>
          </div>
          <div className="clear"></div>
          <AppTablePagination
            paginate={this.props.paginate}
            pagination={this.state.pagination}
            onPageChanged={(page) => this._pageChanged(page)}
          />
        </div>
      </div>
    );
  }
}

// Default props
AppTable.defaultProps = {
  onInit: () => {},
  onLoad: () => {},
  getTableColumns: () => [],
  disableColumn: () => [],
  getColumnActions: () => [],
  getCellClass: () => [],
  getRowClass: () => [],
  renderCell: () => false,
  notRenderCell: () => false,
  params: {},
  parseUrl: true,
  paginate: true,
  filter: [],
  forwardedRef: null,
};

export default withRouter(AppTable);
