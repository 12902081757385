import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppTable from "AdminApp/components/crud/AppTable";
import UserModel from "../users/all-users/models/UserModel";
import AppBadge from "../../components/typography/AppBadge";
import NewUserWidget from "./components/NewUsersWidget";
import NewUsersTrendWidget from "./components/NewUsersTrendWidget";
import TransactionsWidget from "./components/TransactionsWidget";
import KycProcessModel from "../users/all-users/models/UserKYCRequestModel";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};
export class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      totalProjects: 5,
      lastWeek: 10,
      lastMonth: 14,
      lastYear: 29,
      topSchools: [],
      data: [],
      schoolNames: [],
      labels: ["1", "2", "3", "4", "5", "6", "7"],
    };
    this.childRefs = [React.createRef(), React.createRef()];
  }

  componentDidMount() {
    if (this.childRefs) {
      this.childRefs.forEach((ref, index) => {
        if (ref) {
          ref.current.focus();
        }
      });
    }
  }

  _renderStatus(status) {
    switch (status) {
      case "approved":
        return <AppBadge text="Approved" icon="check" />;
      case "declined":
        return <AppBadge text="Declined" icon="times" type="danger" />;
      case "contact_user":
        return <AppBadge text="Contact user" icon="envelope" type="danger" />;
      case "ready_for_approval":
        return (
          <AppBadge text="Ready for approval" icon="clock-o" type="info" />
        );
      case "pending_review":
        return <AppBadge text="Pending review" icon="clock-o" type="info" />;
      case "in_compliance_review":
        return (
          <AppBadge text="In compliance review" icon="clock-o" type="info" />
        );
      case "document_review_in_progress":
        return (
          <AppBadge
            text="Document review in progress"
            icon="clock-o"
            type="info"
          />
        );
      case "pending_poa_documents_from_user":
        return (
          <AppBadge
            text="Pending POA documents from user"
            icon="clock-o"
            type="info"
          />
        );
      case "pending_documents_from_user":
        return (
          <AppBadge text="Waiting for documents" icon="clock-o" type="info" />
        );
      default:
        return <AppBadge text="Unknown" icon="clock-o" type="info" />;
    }
  }

  disableLatestUsersColumn() {
    let ContentManagementAdmin =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_CONTENT_MANAGEMENT_ADMIN");

    if (ContentManagementAdmin) {
      return ["Id", "First Name", "Last Name", "E-mail", "KYC Status"];
    }
    return ["Id", "First Name", "Last Name", "E-mail", "KYC Status", "Actions"];
  }

  disableLatestAccountColumn() {
    let ContentManagementAdmin =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_CONTENT_MANAGEMENT_ADMIN");

    if (ContentManagementAdmin) {
      return ["Id", "First Name", "Last Name", "E-mail", "Status"];
    }
    return [];
  }

  render() {
    let ContentManagementAdmin =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_CONTENT_MANAGEMENT_ADMIN");

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard
        </h1>
        <div className="main-inner" style={{ paddingBottom: 20 }}>
          <NewUserWidget />
          <NewUsersTrendWidget />
          <TransactionsWidget />
        </div>

        <div className="row">
          <div className="col-12">
            <div className="activated-cards">
              {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
              <div className="dark-blur"></div>
              <div className="box-header">
                <h2>
                  <i className="fa fa-users"></i> Latest Users
                </h2>
                <div className="box-header-action">
                  {ContentManagementAdmin ? null : (
                    <Link to="/users/all-users" className="button">
                      List all
                    </Link>
                  )}
                </div>
              </div>
              <div className="box-body">
                <AppTable
                  title={null}
                  forwardedRef={this.childRefs[0]}
                  onInit={(tableUserComponent) =>
                    (this._tableUserComponent = tableUserComponent)
                  }
                  disableColumn={() => this.disableLatestUsersColumn()}
                  filter={null}
                  paginate={false}
                  params={{
                    limit: 10,
                  }}
                  parseUrl={false}
                  order={[
                    {
                      field: "id",
                      direction: "desc",
                    },
                  ]}
                  model={UserModel}
                  renderCell={(column, item, index) => {
                    switch (column.name) {
                      case "kycStatus":
                        return this._renderStatus(item.kycStatus);
                      default:
                        return undefined;
                    }
                  }}
                  getColumnActions={(member, index) => {
                    return (
                      <div>
                        <Link
                          to={`/users/all-users/details/${member.id}`}
                          className="button-info"
                        >
                          Details
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="activated-cards">
              {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
              <div className="dark-blur"></div>
              <div className="box-header">
                <h2>
                  <i className="fa fa-id-card-o"></i> Latest Account
                  Verifications
                </h2>
                <div className="box-header-action">
                  {ContentManagementAdmin ? null : (
                    <Link to="/kyc/requests" className="button">
                      List all
                    </Link>
                  )}
                </div>
              </div>
              <div className="box-body">
                <AppTable
                  title={null}
                  forwardedRef={this.childRefs[1]}
                  onInit={(tableComponent) =>
                    (this._tableComponent = tableComponent)
                  }
                  filter={null}
                  paginate={false}
                  params={{
                    limit: 10,
                  }}
                  parseUrl={false}
                  disableColumn={() => this.disableLatestAccountColumn()}
                  order={[
                    {
                      field: "id",
                      direction: "desc",
                    },
                  ]}
                  model={KycProcessModel}
                  renderCell={(column, item, index) => {
                    switch (column.name) {
                      case "status":
                        return this._renderStatus(item.status);
                      default:
                        return undefined;
                    }
                  }}
                  getColumnActions={(member, index) => {
                    return (
                      <div>
                        <Link
                          to={`/kyc/details/${member.id}`}
                          className="button-info"
                        >
                          Details
                        </Link>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(DashboardPage);
