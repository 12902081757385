import BaseModel from "AdminApp/common/script/models/BaseModel";

export default class KycProcessModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return "kyc/process/list";
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return "kyc/process/details";
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: false,
        type: "field",
      },
      {
        name: "firstName",
        label: "First Name",
        sortable: false,
        type: "field",
      },
      {
        name: "lastName",
        label: "Last Name",
        sortable: false,
        type: "field",
      },
      {
        name: "email",
        label: "E-mail",
        sortable: false,
        type: "field",
      },
      {
        name: "status",
        label: "Status",
        sortable: false,
        type: "field",
      },
      {
        name: null,
        label: "Actions",
        sortable: false,
        type: "actions",
      },
    ];
  }
}
