import React, { Component } from "react";
import FormHandler from "AdminApp/common/script/services/crud/FormHandler";
import ForgotPasswordModel from "AdminApp/common/script/models/security/ForgotPasswordModel";
import InputField from "AdminApp/common/script/components/ui/InputField";
// import { connect } from 'react-redux';
// import { loginTriggerAction } from 'AdminApp/modules/auth/AuthActions';
import { Link } from "react-router-dom";
import FormSubmitWrapper from "AdminApp/common/script/components/crud/FormSubmitWrapper";

// const mapDispatchToProps = dispatch => {
//   return {
//     userLoggedIn: (token) => {
//       return dispatch(loginTriggerAction(token))
//     }
//   }
// }

// @connect(null, mapDispatchToProps)
export default class ForgotPassword extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new ForgotPasswordModel({
        email: "",
      })
    );
  }

  _getModalMessage(data) {
    if (!data.success) {
      // return data.responseJSON.message;
      return data.message;
    } else {
      return data.message;
    }
  }

  /**
   * Render template
   *
   * @returns {XML}
   */
  render() {
    let formHandler = this.formHandler;

    return (
      <div>
        {/*  <div className="container-fluid site-top">
         <p className="helpdesk"><i className="fa fa-phone" aria-hidden="true"></i> Help desk: +44 208 201 1414</p>
        </div>*/}
        <div className="container-fluid login-page">
          <img
            className="logo"
            src={require("AdminApp/assets/img/logo.svg")}
            alt="logo"
          />
          <h1>
            Welcome to Bourgeois Bohème{" "}
            <span style={{ display: "block", textAlign: "center" }}>
              Administration
            </span>
          </h1>
          <div className="login-box">
            <div className="white-blur"></div>
            <div className="inner">
              <div>
                <h2 style={{ textTransform: "uppercase" }}>Reset Password</h2>
                <FormSubmitWrapper
                  showBtn={false}
                  formHandler={formHandler}
                  to="/login"
                  buttonText="Reset password"
                  getModalMessage={(data) => this._getModalMessage(data)}
                >
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    autoComplete={this.state.record.email}
                    classes={"loginInput"}
                    label="Email *"
                    type="email"
                    name="email"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.email}
                    handler={formHandler}
                  />

                  <span className="text-center">
                    {formHandler.getSubmissionStatus()}
                  </span>

                  <Link to="/login" className="back-button">
                    Back
                  </Link>
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>©{new Date().getFullYear()} Pannovate. All rights reserved.</p>
          <p>European Patent Application No. 14704638.7</p>
        </footer>
      </div>
    );
  }
}
