import BaseModel from "AdminApp/common/script/models/BaseModel";

export default class UserGroupUserModel extends BaseModel {
  getListUrl() {
    return "notification-user-group/users";
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: true,
        type: "field",
      },
      {
        name: "fullName",
        label: "Full name",
        sortable: true,
        type: "field",
      },
      {
        name: "email",
        label: "E-mail",
        sortable: false,
        type: "field",
      },
      {
        name: null,
        label: "Actions",
        sortable: false,
        type: "actions",
      },
    ];
  }
}
