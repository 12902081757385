import React from "react";
import AppInfoList from "AdminApp/layouts/AppInfoList";
import moment from "moment";

export default class SentNotificationInfo extends React.Component {
  render() {
    let { sendRequest } = this.props;

    let details = [
      {
        label: "Name",
        value: sendRequest.name,
      },
      {
        label: "Created",
        value: moment(sendRequest.created).format("DD/MM/YYYY HH:mm:ss"),
      },
      {
        label: "User count",
        value: sendRequest.userCount || 0,
      },
      {
        label: "Notification title",
        value: sendRequest.title,
      },
      {
        label: "Notification text",
        value: sendRequest.text,
      },
      {
        label: "Notification icon",
        value: sendRequest.icon,
      },
      {
        label: "Notification type",
        value: sendRequest.type === "personal" ? "account" : "personal",
      },
      {
        label: "Notification group",
        value: sendRequest.userGroup ? sendRequest.userGroup.name : "-",
      },
    ];

    return <AppInfoList items={details} />;
  }
}
