import React from "react";
import { Link } from "react-router-dom";
import LoadingOverlay from "AdminApp/common/script/components/LoadingOverlay";
import Api from "AdminApp/common/script/services/Api";

export default class SubscribersWidget extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      total: 0,
      lastWeek: 0,
      lastMonth: 0,
      lastYear: 0,
    };
  }

  componentDidMount() {
    this._loadStats();
  }

  async _loadStats() {
    const result = await Api.call("user-device/stats");
    if (result.success) {
      this.setState({
        ...result.data,
        loading: false,
      });
    }
  }

  render() {
    let disabledClass = this.props.disabledActionUser ? " disabled-btn" : "";

    return (
      <div className="widget widget-auto-height">
        <div className="dark-blur"></div>
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-users" aria-hidden="true"></i> Total Subscribers
          </h3>
        </div>
        <div className="widget-inner">
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <div className="widget-new-customers">
              <div>
                <i className="fa fa-users" aria-hidden="true"></i>
                <p className="customers-number">
                  {this.state.total} <span>subscribers</span>
                </p>
              </div>
              <ul>
                <li>Last week</li>
                <li> {this.state.lastWeek}</li>
              </ul>
              <ul>
                <li>Last month</li>
                <li> {this.state.lastMonth}</li>
              </ul>
              <ul>
                <li>Last year</li>
                <li> {this.state.lastYear}</li>
              </ul>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 30,
                }}
              >
                <Link
                  to="push-notifications/add-new"
                  className={"button-danger" + disabledClass}
                >
                  Send Push Notification
                </Link>
                <Link
                  to="push-notifications/user-groups/add"
                  className={"button-info" + disabledClass}
                >
                  Create Customer Group
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
