import React from "react";
import Chart from "chart.js";
import LoadingOverlay from "AdminApp/common/script/components/LoadingOverlay";
import Api from "AdminApp/common/script/services/Api";

export default class MessagesSentChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: [],
      labels: [],
    };
  }

  componentDidMount() {
    Chart.defaults.global.defaultFontColor = "white";
    this.setState(
      {
        loading: false,
      },
      () => {
        this._loadData();
      }
    );
  }

  getRandomColor() {
    const colors = [];
    this.state.data.forEach(() => {
      colors.push(this.dynamicColors());
    });
    return colors;
  }

  dynamicColors() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return `rgba(${r},${g},${b}, 0.5)`;
  }

  async _loadData() {
    const result = await Api.call("messages/trend");
    if (result.success) {
      this.setState(
        {
          ...result.data,
          loading: false,
        },
        () => {
          this.renderOrderDotsChart();
        }
      );
    }
  }

  renderOrderDotsChart() {
    const colors = this.getRandomColor();
    const config = {
      type: "bar",
      data: {
        labels: this.state.labels,
        datasets: [
          {
            label: "# of Messages",
            data: this.state.data,
            backgroundColor: colors,
            borderColor: "white",
            borderWidth: 1,
            fill: false,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },

              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };

    const ctx = document.getElementById("messages-sent").getContext("2d");
    window.myScatter = new Chart(ctx, config);
  }

  render() {
    return (
      <div className="widget">
        <div className="dark-blur" />
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-bar-chart" aria-hidden="true" /> Messages Sent
          </h3>
        </div>
        <div className="widget-inner" style={{ padding: "10px" }}>
          {this.state.loading ? (
            <LoadingOverlay />
          ) : (
            <div>
              <canvas
                id="messages-sent"
                style={{ width: "100%", height: "350px" }}
                width="600"
                height="350"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
