import BaseModel from "../../../../common/script/models/BaseModel";
import Api from "../../../../common/script/services/Api";

export default class UserModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return "users/list";
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return "users/details";
  }
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return "users/save";
  }
  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return "users/delete/" + id;
  }

  async getAllUsers() {
    let result = await Api.call("users/list", {
      limit: 1000000000000,
    });
    return result.data;
  }

  async delete(id) {
    let result = await Api.call(this.getDeleteUrl(id));
    return result;
  }

  /**
   * Set user status to enabled or disabled
   * @param {int} userid
   * @param {boolean} isEnabled
   */
  async changeUserStatus(userId, isEnabled) {
    let url = "user/disable";
    if (isEnabled) {
      url = "user/enable";
    }
    let result = await Api.call(url, { id: userId });
    return result;
  }

  async changeUserCharging(userId, isEnabled) {
    let url = "users/set-fee-charge";
    let result = await Api.call(url, {
      id: userId,
      disableCharging: isEnabled,
    });
    return result;
  }

  /**
   * Get changes list url
   */
  getRequestsUrl() {
    return "change-request/list";
  }
  async getRequestChanges(id) {
    let result = await Api.call(this.getRequestsUrl(), {
      id,
    });
    return result.data;
  }
  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: true,
        type: "field",
      },
      {
        name: "firstName",
        label: "First Name",
        sortable: false,
        type: "field",
      },
      {
        name: "lastName",
        label: "Last Name",
        sortable: false,
        type: "field",
      },
      {
        name: "email",
        label: "E-mail",
        sortable: false,
        type: "field",
      },
      {
        name: "kycStatus",
        label: "KYC Status",
        sortable: false,
        type: "field",
      },
      {
        name: "status",
        label: "User Status",
        sortable: false,
        type: "field",
      },
      {
        name: "userStatus",
        label: "Enable / Disable User",
        sortable: false,
        type: "field",
      },
      {
        name: "disableFeeCharge",
        label: "Disable Fee Charging",
        sortable: false,
        type: "field",
      },
      {
        name: "isPersonalManager",
        label: "Is User Personal Manager",
        sortable: false,
        type: "field",
      },
      {
        name: null,
        label: "Actions",
        sortable: false,
        type: "actions",
      },
    ];
  }

  /**
   * Get wallet for the user
   *
   * @param {Integer} id Id of the user
   */
  async getAccount(id) {
    let result = await Api.call("users/wallet", {
      id,
    });

    return result.data;
  }

  /**
   * Get cards of the user
   *
   * @param {Integer} id Id of the user
   */
  async getCards(id) {
    let result = await Api.call("users/cards", {
      id,
    });

    return result.data;
  }

  /**
   * Get payment cards for specific user id
   *
   * @param {Integer} id Id of the user
   */
  async getPaymentCards(id) {
    let result = await Api.call("payment-cards/list", {
      userId: id,
    });
  }

  /**
   * Get reset password url
   */
  getResetPasswordUrl() {
    return "users/password-reset";
  }

  async resetPassword(email) {
    let result = await Api.call(this.getResetPasswordUrl(), {
      email,
    });
    return result.data;
  }

  /**
   * Get KYC approved url
   */
  getKYCApproveUrl() {
    return "users/kyc-status/approve";
  }

  async approve(id) {
    let result = await Api.call(this.getKYCApproveUrl(), {
      id,
    });
    return result.data;
  }

  /**
   * Get KYC reject url
   */
  getKYCRejectUrl() {
    return "users/kyc-status/reject";
  }

  async reject(id) {
    let result = await Api.call(this.getKYCRejectUrl(), {
      id,
    });
    return result.data;
  }

  /**
   * Get KYC Compliance Review url
   */
  getComplianceReviewUrl() {
    return "users/kyc-status/change";
  }

  async onComplianceReview(id) {
    let result = await Api.call(this.getComplianceReviewUrl(), {
      id,
    });
    return result.data;
  }

  async isTakenEmail(email) {
    let response = await Api.call("users/register/check-email", {
      email,
    });

    if (!response.success) {
      return true;
    }

    return false;
  }

  async isTakenNumber(countryCode, phone) {
    let response = await Api.call("users/register/check-phone", {
      countryCode,
      phone,
    });

    if (response.data && response.data.taken) {
      return true;
    }

    return false;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      firstName: {
        presence: {
          allowEmpty: false,
          message: "^Please enter first name",
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: "^Please enter last name",
        },
      },
      email: {
        presence: {
          allowEmpty: false,
          message: "^Please enter an email address",
        },
        email: {
          message: "^Please enter a valid email address",
        },
      },
      mobileCountryCode: {
        presence: {
          allowEmpty: false,
          message: "^Please select country code",
        },
      },
      mobileShort: {
        presence: {
          allowEmpty: false,
          message: "^Please enter mobile number (without country code)",
        },
      },
      birthDate: {
        presence: {
          allowEmpty: false,
          message: "^Please enter birthday",
        },
      },
      addressOne: {
        presence: {
          allowEmpty: false,
          message: "^Please enter address one",
        },
      },
      city: {
        presence: {
          allowEmpty: false,
          message: "^Please enter city",
        },
      },
      postCode: {
        presence: {
          allowEmpty: false,
          message: "^Please enter post code",
        },
      },
      countryId: {
        presence: {
          allowEmpty: false,
          message: "^Please select country",
        },
      },
      role: {
        presence: {
          allowEmpty: false,
          message: "^Please select role for user",
        },
      },
    };
  }

  static getRoleOptions() {
    return [
      { id: "ROLE_USER", name: "User" },
      {
        id: "ROLE_ADMIN_TESTER",
        name: "Admin tester",
      },
      { id: "ROLE_ADMIN", name: "Admin" },
      {
        id: "ROLE_SUPER_ADMIN",
        name: "Super admin",
      },
      {
        id: "ROLE_KYC_ADMIN",
        name: "KYC admin",
      },
      {
        id: "ROLE_CUSTOMER_CARE_ADMIN",
        name: "Customer care admin",
      },
      {
        id: "ROLE_CONTENT_MANAGEMENT_ADMIN",
        name: "Content management admin",
      },
    ];
  }

  static getOccupationList() {
    return [
      {
        id: 1,
        label: "Paid employee",
        value: "paid",
      },
      {
        id: 2,
        label: "Self-employee or owner of business",
        value: "self-owner",
      },
      {
        id: 3,
        label: "Unemployed",
        value: "unemployed",
      },
    ];
  }

  static getPositionList() {
    return [
      {
        id: 1,
        label: "Aerospace & Defense",
        value: "Aerospace & Defense",
      },
      {
        id: 2,
        label: "Air Freight & Couriers",
        value: "Air Freight & Couriers",
      },
      {
        id: 3,
        label: "Airlines",
        value: "Airlines",
      },
      {
        id: 4,
        label: "Auto Components",
        value: "Auto Components",
      },
      {
        id: 5,
        label: "Automobiles",
        value: "Automobiles",
      },
      {
        id: 6,
        label: "Banks",
        value: "Banks",
      },
      {
        id: 7,
        label: "Beverages",
        value: "Beverages",
      },
      {
        id: 8,
        label: "Biotechnology",
        value: "Biotechnology",
      },
      {
        id: 9,
        label: "Building Products",
        value: "Building Products",
      },
      {
        id: 10,
        label: "Chemicals",
        value: "Chemicals",
      },
      {
        id: 11,
        label: "Commercial Services & Supplies",
        value: "Commercial Services & Supplies",
      },
      {
        id: 12,
        label: "Communications Equipment",
        value: "Communications Equipment",
      },
      {
        id: 13,
        label: "Computers & Peripherals",
        value: "Computers & Peripherals",
      },
      {
        id: 14,
        label: "Construction & Engineering",
        value: "Construction & Engineering",
      },
      {
        id: 15,
        label: "Construction Materials",
        value: "Construction Materials",
      },
      {
        id: 16,
        label: "Containers & Packaging",
        value: "Containers & Packaging",
      },
      {
        id: 17,
        label: "Distributors",
        value: "Distributors",
      },
      {
        id: 18,
        label: "Diversified Financials",
        value: "Diversified Financials",
      },
      {
        id: 19,
        label: "Diversified Telecom. Services",
        value: "Diversified Telecom. Services",
      },
      {
        id: 20,
        label: "Electric Utilities",
        value: "Electric Utilities",
      },
      {
        id: 21,
        label: "Electrical Equipment",
        value: "Electrical Equipment",
      },
      {
        id: 22,
        label: "Electronic Equipment/Instruments",
        value: "Electronic Equipment/Instruments",
      },
      {
        id: 23,
        label: "Energy Equipment & Services",
        value: "Energy Equipment & Services",
      },
      {
        id: 24,
        label: "Food & Drug Retailing",
        value: "Food & Drug Retailing",
      },
      {
        id: 25,
        label: "Food Products",
        value: "Food Products",
      },
      {
        id: 26,
        label: "Gas Utilities",
        value: "Gas Utilities",
      },
      {
        id: 27,
        label: "Health Care Equipment & Supplies",
        value: "Health Care Equipment & Supplies",
      },
      {
        id: 28,
        label: "Health Care Providers & Services",
        value: "Health Care Providers & Services",
      },
      {
        id: 29,
        label: "Hotels Restaurants & Leisure",
        value: "Hotels Restaurants & Leisure",
      },
      {
        id: 30,
        label: "Household Durables",
        value: "Household Durables",
      },
      {
        id: 31,
        label: "Household Products",
        value: "Household Products",
      },
      {
        id: 32,
        label: "IT Consulting & Services",
        value: "IT Consulting & Services",
      },
      {
        id: 33,
        label: "Industrial Conglomerates",
        value: "Industrial Conglomerates",
      },
      {
        id: 34,
        label: "Insurance",
        value: "Insurance",
      },
      {
        id: 35,
        label: "Internet & Catalog Retail",
        value: "Internet & Catalog Retail",
      },
      {
        id: 36,
        label: "Internet Software & Services",
        value: "Internet Software & Services",
      },
      {
        id: 37,
        label: "ILeisure Equipment & Products",
        value: "Leisure Equipment & Products",
      },
      {
        id: 38,
        label: "Machinery",
        value: "Machinery",
      },
      {
        id: 39,
        label: "Marine",
        value: "Marine",
      },
      {
        id: 40,
        label: "Media",
        value: "Media",
      },
      {
        id: 41,
        label: "Metals & Mining",
        value: "Metals & Mining",
      },
      {
        id: 42,
        label: "Multi-Utilities",
        value: "Multi-Utilities",
      },
      {
        id: 43,
        label: "Multiline Retail",
        value: "Multiline Retail",
      },
      {
        id: 44,
        label: "Office Electronics",
        value: "Office Electronics",
      },
      {
        id: 45,
        label: "Oil & Gas",
        value: "Oil & Gas",
      },
      {
        id: 46,
        label: "Paper & Forest Products",
        value: "Paper & Forest Products",
      },
      {
        id: 47,
        label: "Pharmaceuticals",
        value: "Pharmaceuticals",
      },
      {
        id: 48,
        label: "Real Estate",
        value: "Real Estate",
      },
      {
        id: 49,
        label: "Road & Rail",
        value: "Road & Rail",
      },
      {
        id: 50,
        label: "Semiconductor Equipment/Products",
        value: "Semiconductor Equipment/Products",
      },
      {
        id: 51,
        label: "Software",
        value: "Software",
      },
      {
        id: 52,
        label: "Specialty Retail",
        value: "Specialty Retail",
      },
      {
        id: 53,
        label: "Textiles & Apparel",
        value: "Textiles & Apparel",
      },
      {
        id: 54,
        label: "Tobacco",
        value: "Tobacco",
      },
      {
        id: 55,
        label: "Trading Companies & Distributors",
        value: "Trading Companies & Distributors",
      },
      {
        id: 56,
        label: "Transportation Infrastructure",
        value: "Transportation Infrastructure",
      },
      {
        id: 57,
        label: "Water Utilities",
        value: "Water Utilities",
      },
      {
        id: 58,
        label: "Wireless Telecom Services",
        value: "Wireless Telecom Services",
      },
      {
        id: 59,
        label: "Other (specify)",
        value: "other",
      },
    ];
  }
}
