import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { AppRoute } from "../../components";
import UserGroupsList from "./user-groups/UserGroupsList";
import AddUserGroup from "./user-groups/AddUserGroup";
import UserGroupDetails from "./user-groups/UserGroupDetails";
import UserGroupEdit from "./user-groups/UserGroupEdit";

export default class UserGroups extends Component {
  render() {
    return (
      <Switch>
        <AppRoute
          path={`${this.props.match.url}/add`}
          component={AddUserGroup}
        />
        <AppRoute
          path={`${this.props.match.url}/details/:id`}
          component={UserGroupDetails}
        />
        <AppRoute
          path={`${this.props.match.url}/edit/:id`}
          component={UserGroupEdit}
        />
        <AppRoute
          path={`${this.props.match.url}`}
          exact
          component={UserGroupsList}
        />
      </Switch>
    );
  }
}
