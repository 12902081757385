import React from "react";
import { withRouter } from "react-router";
import $ from "jquery";
import BootstrapDateTimePicker from "eonasdan-bootstrap-datetimepicker";
import DatetimepickerCSS from "styles/bootstrap/bootstrap-datetimepicker.css";
import AppSelect from "AdminApp/common/script/components/ui/AppSelect";
import _ from "lodash";
import AppCheckboxInput from "../../../common/script/components/ui/AppCheckboxInput";
import Config from "AdminApp/config";
import moment from "moment";

class AppTableFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: this._getInitialFilter(props),
      updateInputValue: false,
    };

    props.onInit(this);
  }

  getFilter() {
    return this.state.filter;
  }

  componentDidMount() {
    this.initDateTimePicker();
  }

  componentDidUpdate(prevState) {
    if (this.state.updateInputValue !== prevState.updateInputValue) {
      this.initDateTimePicker();
    }
  }

  initDateTimePicker() {
    const dateFormat = this.props.filterDateFormat
      ? this.props.filterDateFormat
      : "DD/MM/YYYY HH:mm:ss";
    $(".filter-datetime").each((i, element) => {
      $(element)
        .datetimepicker({
          useCurrent: false,
          format: dateFormat,
          maxDate: moment().endOf("day"),
        })
        .on("dp.show dp.update", function () {
          $(".datepicker-years .picker-switch")
            .removeAttr("title")
            .on("click", function (e) {
              e.stopPropagation();
            });
        })
        .on("dp.change", e => {
          let filter =
            this.state.filter[
              $(e.target).closest(".single-filter-field").data("index")
            ];
          if (!filter || filter.length === 0) return;

          let value = e.date.format ? e.date.format(dateFormat) : null;
          setTimeout(() => {
            $(element).data("DateTimePicker").hide();
            this._changedFilter(filter, value, true);
          }, 50);
        });
    });
  }

  /**
   *
   * @param {Object} props Passed props
   */
  _getInitialFilter(props) {
    let filter = null;
    if (props.location.query.filter) {
      filter = this._joinFilters(props.filter, props.location.query.filter);
    } else {
      filter = props.filter;
    }

    return this._prepareFilter(filter);
  }

  _joinFilters(filter, otherFilters) {
    let mainFilter = [...filter];

    otherFilters.forEach(otherFilter => {
      let existingFilter = mainFilter.find(x => x.name === otherFilter.name);
      if (existingFilter) {
        if (otherFilter.value === Config.initFilterValue) {
          mainFilter = mainFilter.filter(x => x.name !== otherFilter.name);
        } else {
          let indexOf = mainFilter.indexOf(existingFilter);
          mainFilter[indexOf] = otherFilter;
          // mainFilter[indexOf] = {...existingFilter, ...otherFilter};
        }
      } else {
        if (otherFilter.value !== Config.initFilterValue) {
          mainFilter.push(otherFilter);
        }
      }
    });
    return mainFilter;
  }

  _prepareFilter(filters) {
    if (!filters) return [];

    filters.forEach((filter, index) => {
      if (
        filters[index].value === null ||
        typeof filters[index].value === "undefined"
      ) {
        if (filter.type !== "term" || filter.type !== "datetime") {
          filters[index].value = Config.initFilterValue;
        } else {
          filters[index].value = null;
        }
      }
    });

    return filters;
  }

  _getElements(props) {
    let elements = [];

    if (!props.filter || props.filter.length === 0) {
      return elements;
    }

    props.filter.forEach((singleFilter, index) => {
      let innerElement = null;
      switch (singleFilter.type) {
        case "term":
        case "string":
          innerElement = this._getTermField(singleFilter, index);
          break;
        case "datetime":
          innerElement = this._getDatetimeField(singleFilter, index);
          break;
        case "select":
          innerElement = this._getSelectField(singleFilter, index);
          break;
        case "checkbox":
          innerElement = this._getCheckboxField(singleFilter, index);
          break;
        default:
      }

      if (innerElement) {
        elements.push(
          <div
            style={{ display: "inline-block" }}
            className="single-filter-field"
            data-index={index}
            key={"filter" + index}
          >
            {innerElement}
          </div>
        );
      }
    });

    return elements;
  }

  _debounceFilter = _.debounce(() => this._triggerFilter(), 200);

  _getTermField(singlefilter, index) {
    let label = singlefilter.label ? singlefilter.label : null;

    return (
      <div className="filter-field" data-index={index}>
        <label>{label}</label>
        <input
          type={singlefilter.name === "term" ? "search" : "text"}
          placeholder={
            singlefilter?.placeholder ? singlefilter.placeholder : "Search"
          }
          value={
            this.state.filter[index] && this.state.filter[index].value
              ? this.state.filter[index].value.replace(/\*/g, "")
              : null
          }
          onChange={e => {
            this._changedFilter(singlefilter, e.target.value);
            this._debounceFilter();
          }}
        />
      </div>
    );
  }

  _getDatetimeField(singleFilter, index) {
    let label = singleFilter.label ? singleFilter.label : singleFilter.name;

    return (
      <div className="filter-field" data-index={index}>
        <label>{label}</label>
        <input
          key={this.state.updateInputValue}
          className="filter-datetime"
          type="text"
          placeholder=""
          value={
            this.state.filter[index] &&
            this.state.filter[index].value !== Config.initFilterValue
              ? this.state.filter[index].value
              : null
          }
          onChange={e => {
            this._changedFilter(singleFilter, e.target.value);
          }}
        />
      </div>
    );
  }

  _getSelectField(singleFilter, index) {
    let label = singleFilter.label ? singleFilter.label : singleFilter.name;

    return (
      <div className="filter-field" data-index={index}>
        <label>{label}</label>
        <AppSelect
          textFieldProps={{
            disableUnderline: true,
            label: false,
            InputLabelProps: {
              shrink: true,
            },
          }}
          {...singleFilter.options}
          onChange={itemId => {
            this._changedFilter(singleFilter, itemId, true);
          }}
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
        />
      </div>
    );
  }

  _getCheckboxField(singleFilter, index) {
    let label = singleFilter.label ? singleFilter.label : singleFilter.name;

    return (
      <div className="filter-field" data-index={index}>
        <AppCheckboxInput
          name={this.state.filter[index] ? this.state.filter[index].name : null}
          label={label}
          {...singleFilter.options}
          onChange={value => {
            this._changedFilter(singleFilter, value, true);
          }}
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
        />
      </div>
    );
  }

  _getCheckboxReverseField(singleFilter, index) {
    let label = singleFilter.label ? singleFilter.label : singleFilter.name;

    return (
      <div className="filter-field" data-index={index}>
        <AppCheckboxInput
          name={this.state.filter[index] ? this.state.filter[index].name : null}
          label={label}
          {...singleFilter.options}
          onChange={value => {
            this._changedFilter(singleFilter, !value, true);
          }}
          value={
            this.state.filter[index] ? this.state.filter[index].value : null
          }
        />
      </div>
    );
  }

  _changedFilter(filter, filterValue, reload = false) {
    let filterCopy = [...this.state.filter];
    let currentFilter = null;

    if (filter.name) {
      currentFilter = filterCopy.find(x => x.name === filter.name);
    } else {
      currentFilter = filterCopy.find(x => x.field === filter.field);
    }

    if (currentFilter) {
      currentFilter.value = filterValue;
      filterCopy[filterCopy.indexOf(currentFilter)] = currentFilter;
    } else {
      filterCopy.push({
        ...filter,
        value: filterValue,
      });
    }

    if (filterCopy.options) {
      delete filterCopy.options;
    }

    this.setState(
      {
        filter: filterCopy,
        updateInputValue: !this.state.updateInputValue,
      },
      () => {
        if (reload) {
          this._triggerFilter();
        }
      }
    );
  }

  _triggerFilter() {
    this.props.onFilter(this.state.filter);
  }

  _clearFilter() {
    let newFilter = [...this.state.filter];
    newFilter.forEach((filter, index) => {
      if (filter.type !== "term" || filter.type !== "datetime") {
        newFilter[index].value = Config.initFilterValue;
      } else {
        newFilter[index].value = null;
      }
    });

    this.setState(
      {
        filter: newFilter,
        updateInputValue: !this.state.updateInputValue,
      },
      () => {
        this.props.onFilter(this.state.filter);
      }
    );
  }

  _getClearFilterButton() {
    let allEmpty = true;

    this.state.filter.forEach(filter => {
      if (filter.value && filter.value !== Config.initFilterValue)
        allEmpty = false;
    });

    if (allEmpty) return null;

    return (
      <button
        type="button"
        className="button-info"
        onClick={() => this._clearFilter()}
      >
        Clear
      </button>
    );
  }

  render() {
    let elements = this._getElements(this.props);

    if (elements.length === 0) return null;

    let clearFilterButton = this._getClearFilterButton();

    return (
      <form
        onSubmit={() => this._triggerFilter()}
        style={{ padding: this.props.padded ? 10 : 0 }}
      >
        {elements}
        {clearFilterButton}
        <button
          type="submit"
          className="button-round"
          onClick={e => {
            e.preventDefault();
            this._triggerFilter();
            return false;
          }}
        >
          Search
        </button>
      </form>
    );
  }
}

AppTableFilter.defaultProps = {
  onInit: () => {},
  onFilter: () => {},
};

export default withRouter(AppTableFilter);
