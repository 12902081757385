export const MEMBER_LOGIN_TRIGGER = "MEMBER_LOGIN_TRIGGER";
export const MEMBER_TOKEN_LOADED = "MEMBER_TOKEN_LOADED";
export const MEMBER_LOGOUT_TRIGGER = "MEMBER_LOGOUT_TRIGGER";
export const MEMBER_AUTH_REFRESH = "MEMBER_AUTH_REFRESH";
export const MEMBER_AUTH_LOADED = "MEMBER_AUTH_LOADED";
export const MEMBER_TOKEN_PRELOAD = "MEMBER_TOKEN_PRELOAD";
export const SET_CHAT_TOTAL_UNREAD_MESSAGES = "SET_CHAT_TOTAL_UNREAD_MESSAGES";

export const loginTriggerAction = (token, account) => ({
  type: MEMBER_LOGIN_TRIGGER,
  payload: {
    token,
    account,
  },
});
export const logoutTriggerAction = (token) => ({
  type: MEMBER_LOGOUT_TRIGGER,
  payload: {
    // token
  },
});

export const refreshAuthAction = () => ({
  type: MEMBER_AUTH_REFRESH,
});

export const setChatTotalUnreadMessagesAction = (total) => ({
  type: SET_CHAT_TOTAL_UNREAD_MESSAGES,
  payload: {
    total,
  },
});
