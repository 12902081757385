import React, { Component } from "react";
import { connect } from "react-redux";
import FullAdminNavigation from "./sidemenu/FullAdminNavigation";
import KYCAdminNavigation from "./sidemenu/KYCAdminNavigation";
import CustomerCareAdminNavigation from "./sidemenu/CustomerCareAdminNavigation";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContentManagementAdminNavigation from "./sidemenu/ContentManagementAdminNavigation";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};

export class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 1000);
      }
    );
  }

  render() {
    let navigation = <FullAdminNavigation key={window.location.pathname} />;

    if (this.state.loading) {
      navigation = (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress className="circle-progress" size={20} />
        </div>
      );
    }

    let KYCAdmin =
      this.props.profile && this.props.profile.roles.includes("ROLE_KYC_ADMIN");
    if (KYCAdmin) {
      navigation = <KYCAdminNavigation key={window.location.pathname} />;
    }

    let CustomerCareAdmin =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_CUSTOMER_CARE_ADMIN");
    if (CustomerCareAdmin) {
      navigation = (
        <CustomerCareAdminNavigation key={window.location.pathname} />
      );
    }

    let ContentManagementAdmin =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_CONTENT_MANAGEMENT_ADMIN");
    if (ContentManagementAdmin) {
      navigation = (
        <ContentManagementAdminNavigation key={window.location.pathname} />
      );
    }

    return (
      <div className="dashboard-side">
        <div className="dash-logo">
          <img src={require("AdminApp/assets/img/logo.svg")} alt="logo" />
        </div>
        {navigation}
      </div>
    );
  }
}

export default connect(mapStateToProps)(SideBar);
