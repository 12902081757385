import BaseModel from "../../../../common/script/models/BaseModel";
import Api from "../../../../common/script/services/Api";

export default class DevicesModel extends BaseModel {
  /**
   * Get list url
   */
  getListUrl() {
    return "user-device/list";
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return "user-device/details";
  }
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return "user-device/save";
  }
  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return "user-device/delete/" + id;
  }

  async delete(id) {
    let result = await Api.call(this.getDeleteUrl(id));
    return result;
  }
  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: true,
        type: "field",
      },
      {
        name: "deviceId",
        label: "Device ID",
        sortable: false,
        type: "field",
      },
      {
        name: "deviceOs",
        label: "Device OS",
        sortable: false,
        type: "field",
      },
      {
        name: "lastUsedIp",
        label: "Last Used Ip",
        sortable: false,
        type: "field",
      },
      {
        name: null,
        label: "Actions",
        sortable: false,
        type: "actions",
      },
    ];
  }

  /**
   * Get wallet for the user
   *
   * @param {Integer} id Id of the user
   */
  async getAccount(id) {
    let result = await Api.call("users/wallet", {
      id,
    });

    return result.data;
  }

  /**
   * Get cards of the user
   *
   * @param {Integer} id Id of the user
   */
  async getCards(id) {
    let result = await Api.call("users/cards", {
      id,
    });

    return result.data;
  }

  /**
   * Get payment cards for specific user id
   *
   * @param {Integer} id Id of the user
   */
  async getPaymentCards(id) {
    let result = await Api.call("payment-cards/list", {
      userId: id,
    });
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      firstName: {
        presence: {
          allowEmpty: false,
          message: "^Please enter first name",
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: "^Please enter last name",
        },
      },
      email: {
        presence: {
          allowEmpty: false,
          message: "^Please enter an email address",
        },
        email: {
          message: "^Please enter a valid email address",
        },
      },
      mobile: {
        presence: {
          allowEmpty: false,
          message: "^Please enter mobile number",
        },
      },
      birthDate: {
        presence: {
          allowEmpty: false,
          message: "^Please enter birthday",
        },
      },
      addressOne: {
        presence: {
          allowEmpty: false,
          message: "^Please enter address one",
        },
      },
      addressTwo: {
        presence: {
          allowEmpty: false,
          message: "^Please enter address two",
        },
      },
      city: {
        presence: {
          allowEmpty: false,
          message: "^Please enter city",
        },
      },
      postCode: {
        presence: {
          allowEmpty: false,
          message: "^Please enter post code",
        },
      },
      countryName: {
        presence: {
          allowEmpty: false,
          message: "^Please enter country name",
        },
      },
    };
  }
}
