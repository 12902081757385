import { fork, all, takeEvery, put } from 'redux-saga/effects';
import AuthSagas from 'AdminApp/modules/auth/AuthSagas';
import { APP_MOUNTED } from './AppActions';
import { MEMBER_TOKEN_LOADED } from 'AdminApp/modules/auth/AuthActions';

/**
 * Logout user
 *
 * @param {Object} action
 */
function* appMounted(action) {
  try {

    let token = window.localStorage.getItem('AUTH_TOKEN');

    if(token) {
      yield put({ type: MEMBER_TOKEN_LOADED, payload: token });
    }

  } catch(e) {
  }
}

export function* watchAppMounted() {
  yield takeEvery(APP_MOUNTED, appMounted);
}

export default function* allSagas() {
  yield all([
    fork(watchAppMounted),
    fork(AuthSagas)
  ]);
}

