import Config from "AdminApp/config";
import $ from "jquery";
import axios from "axios";
import AppStore from "AdminApp/logic/store/AppStore";
import { logoutTriggerAction } from "AdminApp/modules/auth/AuthActions";

export default class Api {
  static getBaseUrl() {
    return Config.url;
  }

  static call(method, data) {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Promise((resolve, reject) => {
      let headers = {};
      let token = AppStore.getState().auth.token;

      if (token) {
        headers["Authorization"] = "Bearer " + token;
      }
      headers["Timezone"] = timezone;

      $.ajax({
        url: this.getBaseUrl() + method,
        type: "POST",
        contentType: "application/json",
        processData: false,
        data: JSON.stringify(data),
        headers: headers,
        success: function (data) {
          resolve(data);
        },
        error: function (data) {
          if (data.responseJSON) {
            reject(data.responseJSON);
          } else {
            reject({
              message: "An unknown error occurred",
            });
          }
          if (data.status == 403) {
            AppStore.dispatch(logoutTriggerAction());
          }
        },
      });
    });
  }

  static downloadFile(url, params = {}) {
    let headers = {};
    let token = AppStore.getState().auth.token;
    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }

    axios({
      url: this.getBaseUrl() + url,
      method: "POST",
      headers,
      responseType: "blob",
      processData: false,
      data: JSON.stringify(params),
    }).then((response) => {
      let disposition = response.headers["content-disposition"];
      let filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    });
  }
}
