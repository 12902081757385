import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: "rgba(255, 255, 255, 1)" },
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #ffffff!important",
        },
        "&:after": {
          borderBottom: "2px solid #0275d8!important",
        },
      },
      root: {
        // Name of the rule
        color: "rgba(255, 255, 255, 1)", // Some CSS,
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
    MuiInputLabel: {
      root: {
        color: "white",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // height: 240,
    color: "white",
  },
  input: {
    display: "flex",
    // padding: 0,
    color: "white",
    // maxHeight: 60,
    minHeight: 30,
    fontWeight: 300,
    alignItems: "center",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    color: "white",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
    color: "white",
    fontWeight: 300,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
    color: "white",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    cursor: "pointer",
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        fullWidth
        InputProps={{
          disableUnderline: props.selectProps.textFieldProps.disableUnderline,
          color: "white",
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            color: "white",
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    </MuiThemeProvider>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 300 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      style={{ zIndex: 99999 }}
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class AppSelect extends React.Component {
  state = {
    selectedOption: null,
    items: [],
  };

  static getDerivedStateFromProps(props, state) {
    let stateChange = {};
    let selectedOption;
    if (props.value) {
      selectedOption = props.isMulti
        ? state.items.filter((x) =>
            props.value.includes(props.getOptionValue(x))
          )
        : state.items.find(
            (x) => String(props.getOptionValue(x)) === String(props.value)
          );
      props.isMulti &&
        console.warn("selectedOption", selectedOption, props.name);
      stateChange.selectedOption = selectedOption;
    } else {
      stateChange.selectedOption = null;
    }
    if (props.options) {
      stateChange.items = props.options;
    }
    return stateChange;
  }

  handleChange(option) {
    const { isMulti } = this.props;

    this.setState(
      {
        selectedOption: option,
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(
            this.props.name,
            isMulti
              ? option.map((o) => this.props.getOptionValue(o))
              : this.props.getOptionValue(option)
          );
        }

        this.props.onChange(
          isMulti
            ? option.map((o) => this.props.getOptionValue(o))
            : this.props.getOptionValue(option)
        );
      }
    );
  }
  componentDidMount() {
    this._loadItems();
  }

  /**
   * Load items
   */
  async _loadItems() {
    if (!this.props.model) {
      if (this.props.options) {
        let selectedOption = null;
        if (this.props.value) {
          selectedOption = this.props.options.find(
            (x) =>
              String(this.props.getOptionValue(x)) === String(this.props.value)
          );
        }
        this.setState({
          items: this.props.options,
          selectedOption,
        });
        return;
      }
    }

    // Show loader
    this._loadingOn();

    try {
      let results = await this._getModel().fetchFlat(this.props.params ?? {});

      let selectedOption = null;
      if (this.props.value) {
        selectedOption = results.find(
          (x) =>
            String(this.props.getOptionValue(x)) === String(this.props.value)
        );
      }

      let items = results;
      if (this.props.empty) {
        items = [
          {
            id: this.props.emptyValue || "",
            name:
              typeof this.props.empty === "string"
                ? this.props.empty
                : "- any -",
          },
          ...items,
        ];
      }
      this.setState({
        items: items,
        selectedOption,
      });
    } catch (e) {}
    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }
  /**
   * Loading off
   */
  _loadingOn() {
    this.setState({
      loading: true,
    });
  }

  /**
   * Loading on
   */
  _loadingOff() {
    this.setState({
      loading: false,
    });
  }

  getValidationError() {
    let messages = [];

    if (!this.props.handler) return messages;
    this.props.handler
      .getErrors()
      .filter((x) => x.field === this.props.name)
      .forEach((error) => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={this.props.name + "-error"}>
        {messages.join("<br/>")}
      </div>
    );
  }

  render() {
    const { classes, theme, isMulti, isDisabled } = this.props;
    let validationError = this.getValidationError();

    return (
      // <div className='input-group' style={{position: 'relative', width: '100% !important'}}>
      <div
        style={{
          position: "relative",
          marginBottom: "20px",
          color: "white",
          zIndex: 99,
          ...this.props.wrapperStyle,
        }}
        className="select-wrapper"
      >
        <Select
          {...this.props}
          textFieldProps={this.props.textFieldProps}
          classes={classes}
          options={this.state.items}
          components={components}
          value={this.state.selectedOption}
          onChange={(option) => this.handleChange(option)}
          placeholder={this.props.placeholder}
          getOptionValue={(option) => this.props.getOptionValue(option)}
          getOptionLabel={(option) => this.props.getOptionLabel(option)}
          isMulti={isMulti}
          isDisabled={isDisabled}
        />
        {validationError}
      </div>
      //  </div>
    );
  }
}

AppSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

AppSelect.defaultProps = {
  onChange: () => {},
  getOptionValue: (option) => option.id,
  getOptionLabel: (option) => option.name,
};

export default withStyles(styles, { withTheme: true })(AppSelect);
