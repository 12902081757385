import React from "react";
import Api from "../../../common/script/services/Api";
import Chart from "chart.js";

export default class NewUsersTrendWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    Chart.defaults.global.defaultFontColor = "white";
    // Load data and initialize graph
    this._loadData();
  }

  async _loadData() {
    let result = await Api.call("stats/userTrend");

    if (result.success) {
      this.setState(
        {
          ...result.data,
          loading: false,
        },
        () => {
          this.renderOrderDotsChart();
        }
      );
    }
  }

  renderOrderDotsChart() {
    var config = {
      type: "line",
      data: {
        labels: this.state.labels,
        datasets: [
          {
            label: "# of Users",
            data: this.state.data,
            backgroundColor: "rgba(54, 162, 235, 1)",
            borderColor: "rgba(54, 162, 235, 0.4)",
            pointBorderColor: "rgba(255, 255, 255, 1)",
            borderWidth: 2,
            fill: false,
            showLine: true,
            borderDash: [2],
            pointRadius: 6,
            pointHoverRadius: 10,
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        hover: {
          mode: "index",
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                color: "rgba(255, 255, 255, 0.1)",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Month",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: "rgba(255, 255, 255, 0.1)",
              },
              display: true,
              scaleLabel: {
                display: false,
              },
            },
          ],
        },
      },
    };

    var ctx = document.getElementById("chart-dots").getContext("2d");
    window.myScatter = new Chart(ctx, config);
  }

  render() {
    return (
      <div className="widget">
        <div className="dark-blur"></div>
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-bar-chart" aria-hidden="true"></i> New users
          </h3>
        </div>
        <div className="widget-inner" style={{ padding: "10px" }}>
          <div>
            <canvas
              id="chart-dots"
              style={{ width: "100%", height: "300px" }}
              width="600"
              height="300"
            ></canvas>
          </div>
        </div>
      </div>
    );
  }
}
