import React, { Component } from "react";
import FormHandler from "AdminApp/common/script/services/crud/FormHandler";
import LoginModel from "AdminApp/common/script/models/security/LoginModel";
import InputField from "AdminApp/common/script/components/ui/InputField";
import { connect } from "react-redux";
import { loginTriggerAction } from "AdminApp/modules/auth/AuthActions";
import { Link } from "react-router-dom";
import FormSubmitWrapper from "AdminApp/common/script/components/crud/FormSubmitWrapper";

export const REQUIRE_OTP = 2001;

const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedIn: (token, account) => {
      return dispatch(loginTriggerAction(token, account));
    },
  };
};

export class LoginPage extends Component {
  componentDidMount() {
    // $('#slide-login').slideToSubmit({
    //   successText: 'You are logged in...'
    // });
    this.setState({
      record: {
        ...this.state.record,
        email: "",
        password: "",
      },
    });
  }
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginModel(
        {
          email: " ",
          password: " ",
        },
        {
          showPassword: false,
        }
      )
    );
  }

  showPassword() {
    // Toggle the visibility of the password
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  onSubmitSuccess(data) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
    }

    if (data.code === REQUIRE_OTP) {
      this.props.history.push("/login/otp", { otp: data.data });
      // this.props.history.push({
      //   pathname: "/login/otp",
      //   state: data.data, // your data array of objects
      // });
    }
  }
  _getModalMessage(data) {
    if (!data.success) {
      // return data.errors[0].message
      return data.message;
    }
  }

  /**
   * Render template
   *
   * @returns {XML}
   */
  render() {
    let formHandler = this.formHandler;
    return (
      <div>
        <h2>LOGIN</h2>
        <FormSubmitWrapper
          showBtn={false}
          formHandler={formHandler}
          to="/login"
          buttonText="Login"
          getModalMessage={(data) => this._getModalMessage(data)}
        >
          <InputField
            materialProps={{
              fullWidth: true,
            }}
            autoComplete={this.state.record.email}
            classes={"loginInput"}
            label="Email *"
            type="email"
            name="email"
            InputLabelProps={{
              shrink: true,
            }}
            value={this.state.record.email}
            handler={formHandler}
          />
          <div style={{ position: "relative" }}>
            <InputField
              materialProps={{
                fullWidth: true,
              }}
              autoComplete={this.state.record.password}
              label="Password *"
              type={this.state.showPassword ? "text" : "password"}
              name="password"
              InputLabelProps={{
                shrink: true,
              }}
              value={this.state.record.password}
              handler={formHandler}
            />
            <div
              onClick={() => this.showPassword()}
              style={{
                position: "absolute",
                right: 0,
                top: 18,
                cursor: "pointer",
              }}
            >
              <i
                aria-hidden="true"
                // Ternary operator to choose between 'fa.eye' (visible) and 'fa.eye-slash' (hidden)
                className={
                  this.state.showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                }
                // Styling for the icon
                style={{ fontSize: 16, color: "#FFFFFF" }}
              />
            </div>
          </div>

          {/* <span className="text-center">
            {formHandler.getSubmissionStatus()}
          </span> */}

          <Link className="form-sublinks forgot-password" to="/reset-password">
            Forgot Password?
          </Link>
        </FormSubmitWrapper>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(LoginPage);
