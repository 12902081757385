import React, { Component } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AppRoute } from "AdminApp/components";
import LoginPage from "../modules/login/LoginPage";
import ForgotPassword from "../modules/login/ForgotPassword";
import OTPPage from "AdminApp/modules/login/OTPPage";

import "../styles/bootstrap/bootstrap-reboot.min.css";
import "../styles/bootstrap/bootstrap.min.css";
import "../styles/main.scss";
import "../styles/slide-to-submit.scss";

export default class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.match = props.match;
  }

  render() {
    return (
      <div>
        {/*  <div className="container-fluid site-top">
         <p className="helpdesk"><i className="fa fa-phone" aria-hidden="true"></i> Help desk: +44 208 201 1414</p>
        </div>*/}
        <div className="container-fluid login-page">
          <img
            className="logo"
            src={require("AdminApp/assets/img/logo.svg")}
            alt="logo"
          />
          <h1>
            Welcome to Bourgeois Bohème{" "}
            <span style={{ display: "block", textAlign: "center" }}>
              Administration
            </span>
          </h1>
          <div className="login-box">
            <div className="white-blur"></div>
            <div className="inner">
              <Switch>
                {/* <AppRoute path={`${this.match.url}reset-password`} load={() => import('../modules/login/ForgotPassword')} />
                <AppRoute path={`${this.match.url}`} load={() => import('../modules/login/LoginPage')} /> */}
                {/*  <Route path="/reset-password" component={ForgotPassword} />*/}
                <AppRoute
                  path="/login/otp"
                  component={OTPPage}
                  unAuthenticatedOnly
                />
                <Route path="/login" component={LoginPage} />
              </Switch>
            </div>
          </div>
        </div>
        <footer>
          <p>©{new Date().getFullYear()} Pannovate. All rights reserved.</p>
          <p>European Patent Application No. 14704638.7</p>
        </footer>
      </div>
    );
  }
}
