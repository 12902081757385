import BaseModel from "../../../common/script/models/BaseModel";
import Api from "../../../common/script/services/Api";

export default class ChatModel extends BaseModel {
  /**
   * Get list of conversation
   *
   */
  async getConversation(limit) {
    try {
      const result = await Api.call("chat/conversations", {
        page: 1,
        limit,
      });

      if (result.success) {
        return result.data;
      }
    } catch (e) {
      console.log("Error while pulling conversation list: ", e);
    }

    return [];
  }

  /**
   * Get all messages per conversation
   *
   */

  async getAllMessages(id, limit) {
    try {
      const result = await Api.call("chat/conversation/messages", {
        page: 1,
        limit,
        conversationId: id,
      });

      if (result.success) {
        return result.data;
      }
    } catch (e) {
      console.log("Error while pulling messages list: ", e);
    }

    return [];
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return "chat/conversation/details";
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return "chat/send-message";
  }

  async seenMessages(conversationId) {
    return Api.call("chat/messages/seen", {
      conversationId: conversationId,
    });
  }

  async totalUnreadMessages() {
    try {
      const result = await Api.call("chat/total-unread");

      if (result.success) {
        return result;
      }
    } catch (e) {
      console.log("Error while pulling total unread messages: ", e);
    }
    return null;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      toUserId: {
        presence: {
          allowEmpty: false,
          message: "^Please select user",
        },
      },
    };
  }

  customValidation(record) {
    const errors = [];

    const { message } = record;

    if (message !== undefined && message.length === 0) {
      errors.push({
        field: "message",
        message: "Please enter message",
      });
    }

    return errors;
  }
}
