import React from "react";
import AppIcon from "../components/typography/AppIcon";
import AppBlur from "./AppBlur";

export default class AppWidget extends React.Component {
  _getHeader() {
    if (!this.props.title && !this.props.icon) return null;

    return (
      <div className="box-header">
        <h2 className="box-title">
          <AppIcon icon={this.props.icon} /> {this.props.title}
        </h2>
        <div className="box-actions">{this.props.headerActions()}</div>
      </div>
    );
  }

  render() {
    let bodyClasess = ["box-body"];
    if (this.props.padded) {
      bodyClasess.push("padded");
    }
    return (
      <div className="box">
        {this._getHeader()}
        <AppBlur>
          <div className={bodyClasess.join(" ")}>{this.props.children}</div>
        </AppBlur>
      </div>
    );
  }
}

AppWidget.defaultProps = {
  headerActions: () => null,
};
