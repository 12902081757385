import numeral from 'numeral';
import CurrencyHelper from './CurrencyHelper';

export function currency(value = 0, symbol = false) {
  let negative = value < 0;
  let val = numeral(Math.abs(value)).format('0,0.0[0]');

  let returnValue = val;

  if(symbol) {
    if(symbol === true) {
      returnValue = CurrencyHelper.getCurrencySymbol() + returnValue;
    } else {
      returnValue = CurrencyHelper.getCurrencySymbol(symbol) + returnValue;
    }
  }

  if(negative) {
    returnValue = '-' + returnValue;
  }

  return returnValue;
}

export default {
  currency
}
