import BaseModel from "../../models/BaseModel";

export default class LoginOTPModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return "member/login/otp";
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      code: {
        presence: {
          message: "^Please enter the code",
        },
      },
    };
  }
}
