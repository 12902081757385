export function getCurrencySymbol(currency) {
  let map = {
    EUR: "€",
    USD: "$",
    CAD: "$",
    AUD: "$",
    GBP: "£",
    NOK: "kr",
    RSD: "дин",
    DKK: "kr",
    MKD: "MKD",
    SEK: "kr",
    ISK: "kr",
    RUB: "руб",
    PLN: "zł",
    CHF: "CHF",
    CZK: "Kč",
    BAM: "KM",
    ALL: "Lk",
  };

  return map[currency] || currency;
}

export default {
  getCurrencySymbol,
};
