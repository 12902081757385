import React from "react";
import AppFetcher from "AdminApp/components/crud/AppFetcher";
import BreadcrumbHelper from "logic/helpers/BreadcrumbHelper";
import SendNotificationModel from "../../models/SendNotificationModel";
import SentNotificationUsers from "./SentNotificationUsers";
import SentNotificationInfo from "./SentNotificationInfo";
import AppPage from "AdminApp/layouts/AppPage";
import AppWidget from "AdminApp/layouts/AppWidget";

export default class SentNotificationsDetails extends React.Component {
  constructor(props) {
    super(props);

    BreadcrumbHelper.setCrumbs([
      {
        label: "Push notifications",
        link: "/push-notifications",
      },
    ]);
  }

  _renderContent(item) {
    if (!this.firstLoad) {
      BreadcrumbHelper.addCrumb(item.name);
      this.firstLoad = true;
    }

    return (
      <AppPage title={"Sent notification details"} icon={"info"}>
        <div className="row">
          <div className="col-sm-4">
            <AppWidget title={item.name} icon={null} padded>
              <SentNotificationInfo sendRequest={item} />
            </AppWidget>
          </div>

          <div className="col-sm-8">
            <AppWidget title="Actual notifications sent" icon={"list"}>
              <SentNotificationUsers sendRequest={item} />
            </AppWidget>
          </div>
        </div>
      </AppPage>
    );
  }
  render() {
    return (
      <AppFetcher
        ref={(fetcher) => (this._fetcher = fetcher)}
        model={SendNotificationModel}
        id={this.props.match.params.id}
        renderContent={(record) => this._renderContent(record)}
      ></AppFetcher>
    );
  }
}
