import React from "react";
import NotificationUserModel from "../../models/NotificationUserModel";
import AppTable from "AdminApp/components/crud/AppTable";

export default class SentNotificationUsers extends React.Component {
  render() {
    let sendRequest = this.props.sendRequest;

    return (
      <AppTable
        parseUrl={false}
        model={NotificationUserModel}
        onInit={(ref) => {
          this._ref = ref;
          this.props.onInit(ref);
        }}
        params={{
          id: sendRequest.id,
        }}
        notRenderCell={(column, item, index) => {
          if (item.showInList === true) {
            return true;
          }
        }}
        getColumnActions={(item, index) => {
          return (
            <div>
              {/* <Link to={`/tools/bulk-load/details/${bulkLoad.id}/record/${item.id}`} className="button-info">Details</Link> */}
            </div>
          );
        }}
      />
    );
  }
}

SentNotificationUsers.defaultProps = {
  onInit: () => {},
};
