import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import AuthReducers from 'AdminApp/modules/auth/AuthReducers';
import { MEMBER_LOGIN_TRIGGER, MEMBER_TOKEN_LOADED } from 'AdminApp/modules/auth/AuthActions';
import { APP_MOUNTED } from './AppActions';


function network(state = null, action) {
  let newState = {
    error: null
  };

  switch(action.type) {
    case 'NETWORK_ERROR':
      newState.error = action.payload.message;
      break;
    default:
  }
  return newState;
}

function ready(state = false, action) {
  switch(action.type) {
    case MEMBER_LOGIN_TRIGGER:
      return true;
    case MEMBER_TOKEN_LOADED:
      return true;
    case APP_MOUNTED:
      return true;
  }
  return state;
}

export default combineReducers({
  routing: routerReducer,
  ready: ready,
  network: network,
  auth: AuthReducers
});
