import React from "react";

export default class AppTableHead extends React.Component {
  _sortBy(name) {
    let order = [...this.props.order];
    let existingOrder = order.find((x) => x.field == name);

    if (!existingOrder) {
      order.push({
        field: name,
        direction: "asc",
      });
    } else {
      if (existingOrder.direction === "desc") {
        order = order.filter((x) => x.field !== name);
      } else {
        order[order.indexOf(existingOrder)].direction =
          existingOrder.direction === "asc" ? "desc" : "asc";
      }
    }

    this.props.onOrder(order);
  }

  render() {
    let columns = this.props.columns;
    let columnElements = [];

    columns.forEach((column) => {
      let innerElement = column.label;

      if (column.sortable) {
        let chevron = null;
        let currentSort = this.props.order.find((x) => x.field === column.name);
        if (currentSort) {
          chevron = (
            <i
              className={
                "fa fa-" +
                (currentSort.direction === "asc"
                  ? "chevron-up"
                  : "chevron-down")
              }
            ></i>
          );
        }
        innerElement = (
          <a
            href="javascript:void(0)"
            className="app-table-sort-link"
            onClick={() => this._sortBy(column.name)}
          >
            {column.label} {chevron}
          </a>
        );
      }
      columnElements.push(
        <td
          key={"td" + column.name}
          align={column.type === "actions" ? "right" : "left"}
        >
          {innerElement}
        </td>
      );
    });

    return (
      <tr className="tr-dark-bg" style={this.props.style}>
        {columnElements}
      </tr>
    );
  }
}

AppTableHead.defaultProps = {
  onOrder: (order) => console.log("changing order to ", order),
};
