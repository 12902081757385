import React, { Component } from "react";
import SideMenuItem from "./SideMenuItem";
import $ from "jquery";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};

export class FullAdminNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: this.getNavigation(props),
    };
  }

  closeSideMenu() {
    $(".dashboard-side").addClass("collapsed");
    $(".dashboard-main").addClass("full-width");
    $(".dashboard-menu-top").addClass("fullsize");
    $(".breadcrumb").addClass("small");
    $(".collapse-menu").hide();
    $("#collapse").show();
    $(".sub-nav").css({ display: "none" });
    $(".sideMenuList li a span").css({ display: "none" });
    $("#collapse").css({ position: "relative", height: "40px" });
    $(".sub-nav").addClass("toggled-menu");
    $(".arrow").hide();
    $(".dashboard-main ").click(function () {
      $(".toggled-menu").css({ display: "none" });
    });
    $(".toggled-menu li").click(function () {
      $(".toggled-menu").css({ display: "none" });
    });
    $(".arrow").removeClass("arrow-down");
    // let sideMenu = function () {
    //   let el = $('.sideMenuList li');
    //   el.hover(function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').addClass('visible');
    //   }, function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').removeClass('visible');
    //   });
    // }
    // sideMenu();
    window.localStorage.setItem("IS_SIDE_MENU_OPEN", "false");
  }

  showSideMenu() {
    $(".dashboard-side").removeClass("collapsed");
    $(".dashboard-main").removeClass("full-width");
    $(".dashboard-menu-top").removeClass("fullsize");
    $(".breadcrumb").removeClass("small");
    $(".collapse-menu").show();
    $("#collapse").hide();
    $(".arrow").show();
    $(".arrow").removeClass("arrow-down");
    $(".sub-nav").css({ display: "none" });
    $(".sub-nav").removeClass("toggled-menu");
    $(".sideMenuList li a span").css({ display: "inline-block" });
    // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    window.localStorage.setItem("IS_SIDE_MENU_OPEN", "true");
  }

  componentDidMount() {
    // this.closeSideMenu();
    let isSideMenuOpen = window.localStorage.getItem("IS_SIDE_MENU_OPEN");

    if (isSideMenuOpen === "false") {
      this.closeSideMenu();
    }
    // if ($(window).width() < 1440) {
    //   this.closeSideMenu();
    //   $("#collapse").css({ display: "block", "margin-top": "12px" });
    //   $(".arrow").hide();
    // }
    if ($(".dashboard-side").hasClass("collapsed")) {
      $(".arrow").hide();
    } else {
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    }

    let toggleMenu = function () {
      let el = $(".sideMenuList").find("> li").find("> a");
      el.click(function () {
        let elm = $(this);
        let parentLi = elm.closest("li");

        parentLi
          .closest("ul")
          .children()
          .not(parentLi)
          .each(function (index, li) {
            let $li = $(li);
            $li.find(".sub-nav").slideUp();
            $li.find(".arrow").removeClass("arrow-down");
          });

        // console.error(parentLi);

        elm.parent().find(".sub-nav").slideToggle();
        elm.parent().find(".arrow").toggleClass("arrow-down");
      });
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    };
    toggleMenu();
  }

  render() {
    const navigation = this.getNavigation(this.props);
    let items = [];
    navigation.forEach((item, index) => {
      let key = "side-menu-item-" + index;
      items.push(<SideMenuItem {...item} key={key} />);
    });
    return (
      <div className="side-menu">
        <i
          id="collapse"
          onClick={this.showSideMenu}
          style={{
            color: "#fff",
          }}
          className="fa fa-bars"
          aria-hidden="true"
        ></i>

        <p className="collapse-menu" onClick={this.closeSideMenu}>
          <i
            style={{
              color: "#fff",
            }}
            className="fa fa-angle-double-left"
            aria-hidden="true"
          ></i>
          Collapse menu
        </p>

        <ul className="sideMenuList">{items}</ul>
      </div>
    );
  }

  getNavigation(props) {
    const navigation = [
      {
        label: "Dashboard",
        icon: "tachometer",
        path: "/",
      },
      {
        label: "KYC",
        icon: "check",
        path: null,
        children: [
          // {
          //   label: 'Dashboard',
          //   icon: 'tachometer',
          //   path: '/kyc'
          // },
          {
            label: "Requests",
            icon: "id-card-o",
            path: "/kyc/requests",
          },
        ],
      },
      {
        label: "Users",
        icon: "users",
        path: null,
        children: [
          {
            label: "All Users",
            path: "/users/all-users",
            icon: "users",
          },
          {
            label: "Secondary Users",
            path: "/users/secondary-users",
            icon: "users",
          },
          {
            label: " User Devices",
            path: "/users/user-devices",
            icon: "laptop",
          },
        ],
      },
      {
        label: "Personal Managers",
        icon: "user",
        path: "/personal-managers",
      },
      {
        label: "Payment devices",
        icon: "credit-card",
        path: null,
        children: [
          {
            label: "All Payment devices",
            path: "/cards",
            icon: "credit-card",
          },
          {
            label: "Request Load/Unload",
            path: "/cards/request-load-unload",
            icon: "arrow-circle-o-up",
          },
        ],
      },
      {
        label: "Transactions",
        icon: "exchange",
        path: "/transaction",
      },
      {
        label: "Card Management",
        // icon: 'credit-card',
        icon: "credit-card-alt",
        path: null,
        children: [
          {
            label: "Card Types",
            path: "/card-management/card-types",
            icon: "credit-card",
          },
          {
            label: "Card Categories",
            path: "/card-management/card-categories",
            icon: "th",
          },
          {
            label: "Card Products",
            path: "/card-management/card-products",
            icon: "product-hunt",
          },
          {
            label: "Card Presentations",
            path: "/card-management/card-presentations",
            icon: "credit-card",
          },
          // {
          //   label: 'PAN Ranges',
          //   path: '/card-management/pan-ranges',
          //   icon: 'list'
          // }, {
          //   label: 'Serial No. Ranges',
          //   path: '/card-management/serial-ranges',
          //   icon: 'list'
          // }
        ],
      },
      // {
      //   label: 'Analytics',
      //   icon: 'bar-chart',
      //   path: null,
      //   children: [
      //     {
      //       label: 'Schools',
      //       path: '/analytics/schools',
      //       icon: 'university'
      //     },
      //     {
      //       label: 'Projects',
      //       path: '/analytics/projects',
      //       icon: 'rocket'
      //     },
      //     {
      //       label: 'Donations',
      //       path: '/analytics/donations',
      //       icon: 'money'
      //     },
      //     {
      //       label: 'Cards',
      //       path: '/analytics/cards',
      //       icon: 'credit-card',
      //       children: [
      //         {
      //           label: 'Activated cards',
      //           path: '/analytics/cards/activated',
      //           icon: 'line-chart'
      //         },
      //         {
      //           label: 'Loaded cards',
      //           path: '/analytics/cards/loaded',
      //           icon: 'line-chart'
      //         },
      //         {
      //           label: 'Card Spending',
      //           path: '/analytics/cards/spending',
      //           icon: 'line-chart'
      //         },
      //         {
      //           label: 'Card Expiration',
      //           path: '/analytics/cards/expiration',
      //           icon: 'line-chart'
      //         },
      //         // {
      //         //   label: 'Uplift',
      //         //   path: '/analytics/uplift',
      //         //   icon: 'arrow-up'
      //         // },
      //       ]
      //     },
      //     // {
      //     //   label: 'Cards',
      //     //   path: '/card-management/activated',
      //     //   icon: 'list',
      //     //   children: [
      //     //     {
      //     //       label: 'Issued Cards',
      //     //       path: '/card-management/activated',
      //     //       icon: 'power-off'
      //     //     },
      //     //     //  {
      //     //     //   label: 'Redeemed Cards',
      //     //     //   path: '/card-management/redeemed',
      //     //     //   icon: 'money'
      //     //     // },
      //     //     //  {
      //     //     //   label: 'Reloaded Cards',
      //     //     //   path: '/card-management/reloaded',
      //     //     //   icon: 'refresh'
      //     //     // },
      //     //     {
      //     //       label: 'Card Stock',
      //     //       path: '/card-management/stock',
      //     //       icon: 'database'
      //     //     }
      //     //   ]
      //     // },
      //     // {
      //     //   label: 'Promotions',
      //     //   path: '/analytics/promotions',
      //     //   icon: 'gift'
      //     // },
      //     {
      //       label: 'Customers',
      //       path: '/analytics/customers',
      //       icon: 'user'
      //     },
      //   ]
      // },
      {
        label: "Payments",
        icon: "money",
        path: null,
        children: [
          {
            label: "Incoming payments",
            icon: "arrow-down",
            path: "/payments/incoming",
          },
          {
            label: "Outgoing payments",
            icon: "arrow-up",
            path: "/payments/outgoing",
          },
          {
            label: "P2P payments",
            icon: "exchange",
            path: "/payments/p2p",
          },
          {
            label: "External payment requests",
            icon: "external-link",
            path: "/payments/external-payment-requests",
          },
        ],
      },
      {
        label: "Content Management",
        icon: "cog",
        path: null,
        children: [
          {
            label: "Config",
            icon: "cogs",
            path: "/config",
          },
          {
            label: "Faq",
            icon: "question-circle",
            path: "/faq",
          },
          {
            label: "Countries",
            path: "/countries",
            icon: "map",
          },
          {
            label: "Currencies",
            path: "/currencies",
            icon: "money",
          },
          {
            label: "Transaction Categories",
            path: "/transaction-categories",
            icon: "exchange",
          },
          {
            label: "Transaction Merchant",
            path: "/transaction-merchant",
            icon: "exchange",
          },
          {
            label: "Merchant Category Code",
            path: "/merchant-category-code",
            icon: "barcode",
          },
          {
            label: "Tiers",
            path: "/tiers",
            icon: "star-o",
          },
        ],
      },
      // {
      //   label: 'FundStar',
      //   icon: 'star',
      //   path: null,
      //   children: [
      //     {
      //       label: 'Schools',
      //       icon: 'university',
      //       path: '/schools'
      //     },
      //     {
      //       label: 'Projects',
      //       icon: 'rocket',
      //       path: '/projects'
      //     },
      //     {
      //       label: 'Donation Group',
      //       icon: 'handshake-o',
      //       path: '/donation-group'
      //     }
      //   ]
      // },
      {
        label: "Reports",
        icon: "file",
        path: null,
        children: [
          {
            label: "Totals Devices",
            path: "/reports/totals",
            icon: "file-text-o",
          },
          // {
          //   label: 'Schools',
          //   path: '/reports/schools',
          //   icon: 'university'
          // },
          // {
          //   label: 'Projects',
          //   path: '/reports/projects',
          //   icon: 'rocket'
          // },
          {
            label: "Devices",
            path: "/reports/cards",
            icon: "credit-card",
          },
          {
            label: "Totals Accounts",
            path: "/reports/totals-accounts",
            icon: "file-text-o",
          },
          // {
          //   label: 'Donations',
          //   path: '/reports/donations',
          //   icon: 'money'
          // },
          // {
          //   label: 'Donations',
          //   path: '/reports/distributed-donations',
          //   icon: 'money'
          // },
          // {
          //   label: 'Cardholders',
          //   path: '/reports/cardholders',
          //   icon: 'balance-scale'
          // },
          // {
          //   label: 'Fees',
          //   path: '/reports/fees',
          //   icon: 'balance-scale'
          // },
          // {
          //   label: 'Gift Aid',
          //   path: '/reports/giftaid',
          //   icon: 'balance-scale'
          // },
        ],
      },
      {
        label: "Events",
        icon: "file-text-o",
        path: "/events",
        children: [],
      },
      {
        label: "Legal",
        icon: "balance-scale",
        path: null,
        children: [
          {
            label: "Terms and Conditions",
            path: "/terms",
            icon: "balance-scale",
          },
          {
            label: "Privacy Policy",
            path: "/privacy",
            icon: "balance-scale",
          },
        ],
      },
      {
        label: "Notifications",
        icon: "bell",
        // path: '/push-notifications',
        children: [
          {
            label: "Push dashboard",
            path: "/push-notifications",
            icon: "dashboard",
          },
          {
            label: "Send new notification",
            path: "/push-notifications/add-new",
            icon: "plus",
          },
          {
            label: "Sent notifications",
            path: "/push-notifications/sent-notifications",
            icon: "paper-plane",
          },
          {
            label: "User Groups",
            path: "/push-notifications/user-groups",
            icon: "users",
          },
        ],
      },
      {
        label: "Shop",
        icon: "shopping-basket",
        path: null,
        children: [
          {
            label: "Products",
            path: "/shop/products",
            icon: "product-hunt",
          },
          {
            label: "Categories",
            path: "/shop/categories",
            icon: "list-alt",
          },
          {
            label: "Collections",
            path: "/shop/collections",
            icon: "list",
          },
          {
            label: "Purchased products",
            path: "/shop/purchase-products",
            icon: "cart-arrow-down",
          },
        ],
      },
      {
        label: "Tools",
        icon: "cog",
        path: null,
        children: [
          {
            label: "Bulk Issue",
            path: "/bulk-issue",
            icon: "credit-card",
          },
        ],
      },
    ];

    if (props.profile && props.profile.isPersonalManager) {
      navigation.push({
        label: "Chat",
        icon: "comments-o",
        path: "/chat",
        totalChatUnreadMessagesIndicator: true,
      });
    }

    return navigation;
  }
}

export default connect(mapStateToProps)(FullAdminNavigation);
