import React from "react";
import ModalDialog from "elements/modal/ModalDialog";
import TextField from "@material-ui/core/TextField";
import SendNotificationModel from "./models/SendNotificationModel";
import AppSelect from "AdminApp/common/script/components/ui/AppSelect";
import TargetUsers from "./components/TargetUsers";
import FormHandler from "AdminApp/common/script/services/crud/FormHandler";
import InputField from "AdminApp/common/script/components/ui/InputField";
import FormSubmitWrapper from "AdminApp/common/script/components/crud/FormSubmitWrapper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};
class AddNewPush extends React.Component {
  constructor() {
    super();

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new SendNotificationModel({
        name: "",
        title: "",
        text: "",
        type: "promotion",
        icon: "info",
        sendTo: "all",
        action: "",
        actionPayload: {},
        filter: {},
      }),
      {
        showModal: false,
      }
    );
  }

  openModal(e) {
    e.preventDefault();
    this.setState({
      showModal: true,
    });
  }

  onClose() {
    this.setState({
      showModal: false,
    });
  }

  componentDidMount() {
    // $(this.refs.element)
    //   .find("select")
    //   .select2({
    //     minimumResultsForSearch: -1
    //   });
    // $("#datetimepicker1").datetimepicker();
    // $("#datetimepicker2").datetimepicker();
  }

  _getNotificationTypeOptions() {
    return [
      {
        id: "promotion",
        name: "Personal",
      },
      {
        id: "personal",
        name: "Account",
      },
    ];
  }

  _getNotificationIconOptions() {
    return [
      {
        id: "info",
        name: "Info",
      },
      {
        id: "danger",
        name: "Danger",
      },
    ];
  }

  _getTargetOptions() {
    return [
      {
        id: "all",
        name: "All users",
      },
      {
        id: "one",
        name: "Specific user",
      },
      {
        id: "test_groups",
        name: "Test groups",
      },
      {
        id: "group",
        name: "User group",
      },
    ];
  }

  render() {
    let formHandler = this.formHandler;

    let disabledActionUser =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_ADMIN_TESTER");

    return (
      <div className="credit-card-management">
        <h1>
          <i className="fa fa-plus" aria-hidden="true"></i> Send Notification
        </h1>
        <div className="element-with-blur">
          <div className="dark-blur"></div>
          <div className="box-header">
            <h2>
              <i className="fa fa-plus"></i> Send New Notification{" "}
            </h2>
          </div>
          <div className="add-new-group" ref="element">
            <FormSubmitWrapper
              formHandler={formHandler}
              to="/push-notifications"
              buttonText="Send"
              disabledSubmit={disabledActionUser}
            >
              <div className="row">
                <div className="col-md-6">
                  <h4>Basic info</h4>
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    customInput={TextField}
                    label="Notification name"
                    note="Note: this won't be visible by end users"
                    type="text"
                    name="name"
                    value={this.state.record.name}
                    handler={formHandler}
                  />
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    customInput={TextField}
                    label="Notification title"
                    type="text"
                    name="title"
                    value={this.state.record.title}
                    handler={formHandler}
                  />
                  <InputField
                    materialProps={{
                      fullWidth: true,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    customInput={TextField}
                    label="Notification text"
                    type="textarea"
                    name="text"
                    value={this.state.record.text}
                    handler={formHandler}
                  />

                  <h4 style={{ marginTop: 40 }}>Extra fields</h4>
                  <AppSelect
                    textFieldProps={{
                      label: "Notification type",
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    wrapperStyle={{ zIndex: 9999 }}
                    options={this._getNotificationTypeOptions()}
                    name="type"
                    value={this.state.record.type}
                    handler={formHandler}
                    placeholder="Notification type"
                  />

                  <AppSelect
                    textFieldProps={{
                      label: "Notification icon",
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    options={this._getNotificationIconOptions()}
                    name="icon"
                    value={this.state.record.icon}
                    handler={formHandler}
                    placeholder="Notification icon"
                  />
                </div>

                <div className="col-md-6 col-md-offset-1 user-group-filters">
                  <h4>Target users</h4>
                  <AppSelect
                    textFieldProps={{
                      label: "Send to",
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    wrapperStyle={{ zIndex: 9999 }}
                    options={this._getTargetOptions()}
                    name="sendTo"
                    value={this.state.record.sendTo}
                    handler={formHandler}
                    placeholder="Notification icon"
                  />

                  <TargetUsers
                    formHandler={formHandler}
                    sendTo={this.state.record.sendTo}
                  />
                </div>
              </div>
            </FormSubmitWrapper>
          </div>
        </div>
        <ModalDialog
          show={this.state.showModal}
          onClose={this.onClose.bind(this)}
          style={{ position: "relative", backgroundColor: "transparent" }}
        >
          <img
            src="/img/push-notification.png"
            alt=""
            style={{ maxHeight: "500px", display: "block", margin: "auto" }}
          />
        </ModalDialog>
      </div>
    );
  }
}

export default connect(mapStateToProps)(AddNewPush);
