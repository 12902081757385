import React from "react";
import Api from "../../../common/script/services/Api";
import Chart from "chart.js";

export default class TransactionsWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    this._loadData();
  }

  /**
   * Load data
   */
  async _loadData() {
    let result = await Api.call("stats/transactions");

    if (result.success) {
      this.setState(
        {
          ...result.data,
          loading: false,
        },
        () => {
          this._renderChart();
        }
      );
    }
  }

  _renderChart() {
    var barChartConfig = {
      type: "bar",
      data: {
        labels: this.state.labels,
        datasets: [
          {
            label: "# of Transactions",
            data: this.state.data,
            backgroundColor: "rgba(0, 174, 239, 0.5)",
            borderColor: "rgba(0, 174, 239, 1)",
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                color: "rgba(255, 255, 255, 0.1)",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                color: "rgba(255, 255, 255, 0.1)",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };

    var ctx3 = document.getElementById("barChartConfig").getContext("2d");
    window.barChartConfig = new Chart(ctx3, barChartConfig);
  }

  render() {
    return (
      <div className="widget">
        <div className="dark-blur"></div>
        <div className="widget-header">
          <h3 className="widget-title">
            <i className="fa fa-bar-chart" aria-hidden="true"></i> Transactions
          </h3>
        </div>
        <div className="widget-inner" style={{ padding: "10px" }}>
          <div style={{ width: "100%", height: "100%" }}>
            <canvas
              id="barChartConfig"
              style={{ width: "100%", height: "300px" }}
              width="600"
              height="300"
            ></canvas>
          </div>
        </div>
      </div>
    );
  }
}
