const BreadcrumbHelper = {
  crumbs: [],
  callables: [],

  addCrumb(label, link = null) {
    this.crumbs.push({
      label,
      link,
    });
    this.updated();
  },

  setLastCrumb(label) {
    if (this.crumbs.length > 0) {
      this.crumbs[this.crumbs.length - 1].label = label;
      this.updated();
    }
  },

  setCrumbs(crumbs) {
    this.crumbs = crumbs;
    this.updated();
  },

  clearCrumbs() {
    this.setCrumbs([]);
  },

  getCrumbs() {
    return this.crumbs;
  },

  onUpdate(callable) {
    this.callables.push(callable);
  },

  updated() {
    for (let i = 0; i < this.callables.length; i++) {
      let callable = this.callables[i];
      callable(this.crumbs);
    }
  },
};

export default BreadcrumbHelper;
