import React from "react";
import AppIcon from "../components/typography/AppIcon";

export default class AppInfoList extends React.Component {
  _getTitle() {
    if (!this.props.title) return null;

    let icon = this.props.icon;

    return (
      <React.Fragment>
        <h4>
          {icon ? <AppIcon icon={icon} /> : null} {this.props.title}
        </h4>
        <hr />
      </React.Fragment>
    );
  }

  _getItems() {
    let items = this.props.items;

    let itemElements = [];

    items.forEach((element, index) => {
      itemElements.push(
        <React.Fragment key={index}>
          <dt>{element && element.label}</dt>
          <dd>{element && element.value}</dd>
        </React.Fragment>
      );
    });

    return <dl>{itemElements}</dl>;
  }

  render() {
    return (
      <React.Fragment>
        {this._getTitle()}
        {this._getItems()}
      </React.Fragment>
    );
  }
}
