import React from "react";
import UserModel from "../../users/all-users/models/UserModel";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";
import AppSelect from "AdminApp/common/script/components/ui/AppSelect";

export default class TargetUsers extends React.Component {
  state = {
    userId: null,
  };

  _selectOneUser(formHandler) {
    return (
      <AppSelect
        key="user"
        textFieldProps={{
          label: "Select user",
          InputLabelProps: {
            shrink: true,
          },
        }}
        model={UserModel}
        name="userId"
        value={formHandler.state.record.userId}
        handler={formHandler}
        placeholder="Select user"
        getOptionLabel={(user) =>
          user.id ? user.fullName + " (ID:" + user.id + ")" : "- any -"
        }
      />
    );
  }

  _selectUserGroup(formHandler) {
    return (
      <AppSelect
        key="group"
        textFieldProps={{
          label: "Select user group",
          InputLabelProps: {
            shrink: true,
          },
        }}
        model={NotificationUserGroupModel}
        name="userGroupId"
        value={formHandler.state.record.userGroupId}
        handler={formHandler}
        placeholder="Select user group"
        getOptionLabel={(user) => (user.id ? user.name : "- any -")}
      />
    );
  }

  render() {
    let { sendTo, formHandler } = this.props;

    if (sendTo == "all") return null;

    if (sendTo == "one") {
      return this._selectOneUser(formHandler);
    }

    if (sendTo == "group") {
      return this._selectUserGroup(formHandler);
    }

    return null;
  }
}
