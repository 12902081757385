import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import {
  MEMBER_LOGIN_TRIGGER,
  MEMBER_TOKEN_LOADED,
  MEMBER_LOGOUT_TRIGGER,
  MEMBER_AUTH_LOADED,
  MEMBER_TOKEN_PRELOAD,
  SET_CHAT_TOTAL_UNREAD_MESSAGES,
} from "AdminApp/modules/auth/AuthActions";

function token(state = null, action) {
  switch (action.type) {
    case MEMBER_TOKEN_PRELOAD:
      return action.payload;
    case MEMBER_LOGIN_TRIGGER:
      return action.payload.token;
    case MEMBER_TOKEN_LOADED:
      return action.payload;
    case MEMBER_LOGOUT_TRIGGER:
      return null;
  }
  return state;
}

function profile(state = null, action) {
  switch (action.type) {
    case MEMBER_AUTH_LOADED:
      return action.payload.profile;
    case MEMBER_LOGOUT_TRIGGER:
      return null;
  }
  return state ? { ...state } : state;
}

function chat(state = null, action) {
  switch (action.type) {
    case SET_CHAT_TOTAL_UNREAD_MESSAGES:
      return {
        totalUnreadMessages: action.payload.total,
      };
  }
  return state ? { ...state } : state;
}

export default combineReducers({
  token,
  profile,
  chat,
});
