import logger from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createBrowserHistory'
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import appReducers from './AppReducers';
import appSagas from './AppSagas';
import qs from 'qs';
import Config from 'AdminApp/config';


export const history = createHistory();

const appendQueryToLocation = () => {
  history.location = Object.assign(history.location,
    // parse the search string using your package of choice
    { query: qs.parse(history.location.search, { ignoreQueryPrefix: true }) }
  )
}
appendQueryToLocation();
history.listen(() => {
  appendQueryToLocation();
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

let appStore = null;

// console.log(Config.env);
if(Config.env === 'dev') {
  appStore = createStore(
    connectRouter(history)(appReducers),
    composeWithDevTools(
      applyMiddleware(logger, sagaMiddleware, routerMiddleware(history))
    )
  );
} else {
  appStore = createStore(
    connectRouter(history)(appReducers),
    applyMiddleware(sagaMiddleware, routerMiddleware(history))
  );
}

sagaMiddleware.run(appSagas);

export default appStore;
