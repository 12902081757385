import React from "react";
import TextField from "@material-ui/core/TextField";
import AppCheckboxInput from "AdminApp/common/script/components/ui/AppCheckboxInput";
import InputField from "AdminApp/common/script/components/ui/InputField";

export default class GroupConditions extends React.Component {
  render() {
    let formHandler = this.props.formHandler;

    return (
      <React.Fragment>
        <p>Conditions</p>

        <div className="dark-well">
          <p style={{ marginBottom: 0 }}>User registration date</p>
          <div className="row">
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="Registered from"
                type="date"
                name="filter.createdFrom"
                value={formHandler.state.record.filter.createdFrom}
                handler={formHandler}
              />
            </div>
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="Registered to"
                type="date"
                name="filter.createdTo"
                value={formHandler.state.record.filter.createdTo}
                handler={formHandler}
              />
            </div>
          </div>
          <div className="text-center" style={{ marginTop: 20 }}>
            OR
          </div>
          <div className="text-center" style={{ marginTop: 20 }}>
            <div className="row">
              <div className="col-sm-6" style={{ margin: "auto" }}>
                <InputField
                  materialProps={{
                    fullWidth: true,
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  customInput={TextField}
                  label="Registered X days before current date"
                  type="number"
                  name="filter.registeredBefore"
                  value={formHandler.state.record.filter.registeredBefore}
                  handler={formHandler}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="dark-well">
          <p style={{ marginBottom: 0 }}>Last activity date</p>
          <div className="row">
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="From"
                type="date"
                name="filter.lastActivityFrom"
                value={formHandler.state.record.filter.lastActivityFrom}
                handler={formHandler}
              />
            </div>
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="To"
                type="date"
                name="filter.lastActivityTo"
                value={formHandler.state.record.filter.lastActivityTo}
                handler={formHandler}
              />
            </div>
          </div>
        </div>

        <div className="dark-well">
          <p style={{ marginBottom: 0 }}>Last transaction date</p>
          <div className="row">
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="From"
                type="date"
                name="filter.lastTransactionFrom"
                value={formHandler.state.record.filter.lastTransactionFrom}
                handler={formHandler}
              />
            </div>
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="To"
                type="date"
                name="filter.lastTransactionTo"
                value={formHandler.state.record.filter.lastTransactionTo}
                handler={formHandler}
              />
            </div>
          </div>
        </div>

        {/* <div className="dark-well">
          <p style={{ marginBottom: 0 }}>Balance</p>
          <div className="row">
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="From"
                type="number"
                name="filter.balanceFrom"
                value={formHandler.state.record.filter.balanceFrom}
                handler={formHandler}
              />
            </div>
            <div className="col-md-6">
              <InputField
                materialProps={{
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                customInput={TextField}
                label="To"
                type="number"
                name="filter.balanceTo"
                value={formHandler.state.record.filter.balanceTo}
                handler={formHandler}
              />
            </div>
          </div>
        </div> */}

        <div className="dark-well">
          <p style={{ marginBottom: 0 }}>Card details</p>
          <div className="row">
            <div className="col-md-6">
              <AppCheckboxInput
                name="filter.cardNotOrdered"
                value={formHandler.state.record.filter.cardNotOrdered}
                handler={formHandler}
                label="Card not ordered"
              />
            </div>
            <div className="col-md-6">
              <AppCheckboxInput
                name="filter.cardNotActivated"
                value={formHandler.state.record.filter.cardNotActivated}
                handler={formHandler}
                label="Card Not activated"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
