import React from "react";
import { Link } from "react-router-dom";

export default class PushNotifications2 extends React.Component {
  render() {
    return (
      <div className="credit-card-management">
        <div className="activated-cards">
          {/* <h1><i className="fa fa-bell" aria-hidden="true"></i> Add New Push Notifications</h1> */}
          <div className="dark-blur"></div>
          <div className="box-header">
            <h2>
              <i className="fa fa-bell"></i> System Notifications{" "}
            </h2>
          </div>
          {/* <div className="push">
          <form action="">
            <label htmlFor="title">Choose title</label>
            <input type="text" id="title" placeholder="" />
            <label htmlFor="icon">Choose Icon</label>
            <input type="text" id="icon" placeholder="" />
            <label htmlFor="text">Notification Text</label>
            <textarea name="" id="text" cols="" rows="5"></textarea>
            <button className="button">Send PUSH</button>
          </form>
        </div> */}
          <form
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              position: "relative",
              marginBottom: "15px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "100%" }}>
              <input
                type="search"
                style={{ maxWidth: "25%", marginRight: "20px" }}
                placeholder="Search"
              />
              <button type="submit" className="button-round width-180">
                Apply
              </button>
            </div>
            <Link to="/push-notifications/add-new">
              <button type="button" className="button-round width-180">
                Add New
              </button>
            </Link>
          </form>
          <div className="box-body">
            <table className="table">
              <tbody>
                <tr className="tr-dark-bg">
                  <td style={{ width: "10%" }}>Id</td>
                  <td>Title</td>
                  <td>User Group</td>
                  <td>No. of Messages</td>
                  <td>Date</td>
                  {/* <td></td> */}
                  <td align="center" style={{ width: "20%" }}>
                    Actions
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>Message 1</td>
                  <td>10</td>
                  <td>25000</td>
                  <td>02/8/20107</td>
                  <td align="center">
                    <Link
                      to="/push-notifications/push-details"
                      className="button-info"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Message 2</td>
                  <td>2</td>
                  <td>100</td>
                  <td>18/7/20107</td>
                  <td align="center">
                    <Link
                      to="/push-notifications/push-details"
                      className="button-info"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Message 3</td>
                  <td>40</td>
                  <td>125000</td>
                  <td>12/6/20107</td>
                  <td align="center">
                    <Link
                      to="/push-notifications/push-details"
                      className="button-info"
                    >
                      Details
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
