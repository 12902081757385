import React from "react";
import AppFetcher from "AdminApp/components/crud/AppFetcher";
import AppPage from "AdminApp/layouts/AppPage";
import AppWidget from "AdminApp/layouts/AppWidget";
import BreadcrumbHelper from "logic/helpers/BreadcrumbHelper";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";
import GroupInfo from "./components/GroupInfo";
import GroupUsers from "./components/GroupUsers";
import GroupFilters from "./components/GroupFilters";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};
class UserGroupDetails extends React.Component {
  constructor(props) {
    super(props);

    BreadcrumbHelper.setCrumbs([
      {
        label: "User Groups",
        link: "/push-notifications/user-groups",
      },
    ]);
  }

  _renderContent(item) {
    let disabledActionUser =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_ADMIN_TESTER");
    let disabledClass = disabledActionUser ? " disabled-btn" : "";

    if (!this.firstLoad) {
      BreadcrumbHelper.addCrumb(item.name);
      this.firstLoad = true;
    }

    return (
      <AppPage title={"Group Details"} icon={"info"}>
        <div className="row">
          <div className="col-sm-4">
            <AppWidget
              title={item.name}
              icon={null}
              padded
              headerActions={() => (
                <div className="my-3 mr-4">
                  <Link
                    className={"btn-block button-info" + disabledClass}
                    to={`/push-notifications/user-groups/edit/${item.id}`}
                  >
                    Edit
                  </Link>
                </div>
              )}
            >
              <GroupInfo group={item} />
            </AppWidget>
            <AppWidget title={"Filters"} icon={null} padded>
              <GroupFilters group={item} />
            </AppWidget>
          </div>

          <div className="col-sm-8">
            <AppWidget title="Group Users" icon={"list"}>
              <GroupUsers group={item} />
            </AppWidget>
          </div>
        </div>
      </AppPage>
    );
  }
  render() {
    return (
      <AppFetcher
        ref={(fetcher) => (this._fetcher = fetcher)}
        model={NotificationUserGroupModel}
        id={this.props.match.params.id}
        renderContent={(record) => this._renderContent(record)}
      ></AppFetcher>
    );
  }
}

export default connect(mapStateToProps)(UserGroupDetails);
