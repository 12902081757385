import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import SentNotifications from "./SentNotifications";
import PushNotifications2 from "./PushNotifications2";
import AddNewPush from "./AddNewPush";
import PushDashboard from "./PushDashboard";
import PushDetails from "./details/PushDetails";
import SentNotificationsDetails from "./push-notifications/components/SentNotificationsDetails";
import UserGroups from "./UserGroups";

export default class PushNotificationsPage extends Component {
  /**
   * Get active tab
   *
   * @returns {string}
   */
  getActiveTab() {
    let active = "promotional";
    // let currentPath = this.context.router.route.location.pathname;
    let currentPath = window.location.pathname;

    if (currentPath.match(/\/push-notifications\/system.*/)) {
      active = "system";
    }

    return active;
  }

  /**
   * Render
   *
   * @returns {XML}
   */
  render() {
    let activeTab = this.getActiveTab();

    return (
      <div className="dashboard-management">
        {/* <nav>
          <ul className="dashboard-management-nav customers-dashboard-nav">
              <li className={activeTab === 'promotional' ? 'active' : ''} style={{maxWidth: '20%'}}>
                <Link to="/push-notifications"  id="promotional">Promotional notifications</Link>
              </li>
              <li className={activeTab === 'system' ? 'active' : ''} style={{maxWidth: '20%'}}>
                <Link to="/push-notifications/system" id="system">System notifications</Link>
              </li>
          </ul>
        </nav> */}

        <Switch>
          <Route
            path="/push-notifications/push-details/"
            component={PushDetails}
          />
          <Route path="/push-notifications/add-new" component={AddNewPush} />
          <Route
            path="/push-notifications/system"
            component={PushNotifications2}
          />
          <Route
            path="/push-notifications/user-groups"
            component={UserGroups}
          />
          <Route
            path="/push-notifications/details/:id"
            component={SentNotificationsDetails}
          />
          <Route
            path="/push-notifications/sent-notifications"
            component={SentNotifications}
          />
          <Route path="/push-notifications" exact component={PushDashboard} />
        </Switch>
      </div>
    );
  }
}

// Inject router
PushNotificationsPage.contextTypes = {
  router: PropTypes.object,
};
