import React, { Component } from "react";

import { AppRoute } from "AdminApp/components";
import { Switch } from "react-router-dom";
import DashboardPage from "../modules/dashboard/DashboardPage";
import TopBar from "./elements/TopBar";
import SideBar from "./elements/SideBar";
import Breadcrumb from "elements/shared/Breadcrumbs";
import BreadcrumbHelper from "logic/helpers/BreadcrumbHelper";
import PushNotificationsPage from "../modules/push/PushNotificationsPage";

export default class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.match = props.match;
  }

  /**
   * Fast fix for top scrolling
   */
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  /**
   * Clear breacrumbs
   */
  componentWillUpdate() {
    BreadcrumbHelper.clearCrumbs();
  }

  /**
   * Render default layout
   *
   * @returns {XML}
   */
  render() {
    return (
      <div>
        <TopBar />
        <div className="dashboard">
          <SideBar {...this.props} />
          <Breadcrumb />

          <div className="dashboard-main">
            <Switch>
              <AppRoute
                path={`${this.match.url}reports/totals`}
                load={() =>
                  import("../modules/reports/pages/totals/TotalsReports")
                }
              />
              <AppRoute
                path={`${this.match.url}reports/schools`}
                load={() =>
                  import("../modules/reports/pages/schools/SchoolReports")
                }
              />
              <AppRoute
                path={`${this.match.url}reports/projects`}
                load={() =>
                  import("../modules/reports/pages/projects/ProjectReports")
                }
              />
              <AppRoute
                path={`${this.match.url}reports/cards`}
                load={() =>
                  import("../modules/reports/pages/cards/CardsReports")
                }
              />
              <AppRoute
                path={`${this.match.url}reports/totals-accounts`}
                load={() =>
                  import(
                    "../modules/reports/pages/totals/TotalsAccountsReports"
                  )
                }
              />
              <AppRoute
                path={`${this.match.url}reports/donations`}
                load={() =>
                  import("../modules/reports/pages/donations/DonationsReports")
                }
              />
              <AppRoute
                path={`${this.match.url}reports/distributed-donations`}
                load={() =>
                  import(
                    "../modules/reports/pages/donations/DistributedDonationsReports"
                  )
                }
              />
              <AppRoute
                path={`${this.match.url}reports/cardholders`}
                load={() =>
                  import("../modules/reports/pages/CardHolderReports")
                }
              />
              <AppRoute
                path={`${this.match.url}reports/fees`}
                load={() => import("../modules/reports/pages/FeeReports")}
              />
              <AppRoute
                path={`${this.match.url}reports/giftaid`}
                load={() =>
                  import("../modules/reports/pages/gift-aid/GiftAidReports")
                }
              />
              <AppRoute
                path={`${this.match.url}cards`}
                load={() => import("../modules/cards/Cards")}
              />
              <AppRoute
                path={`${this.match.url}schools`}
                load={() => import("../modules/schools/Schools")}
              />
              <AppRoute
                path={`${this.match.url}projects`}
                load={() => import("../modules/projects/Projects")}
              />
              <AppRoute
                path={`${this.match.url}donation-group`}
                load={() => import("../modules/donation-group/DonationGroup")}
              />
              <AppRoute
                path={`${this.match.url}legal`}
                load={() => import("../modules/legal/LegalPage")}
              />
              <AppRoute
                path={`${this.match.url}terms`}
                load={() => import("../modules/terms/TermsAndConditions")}
              />
              <AppRoute
                path={`${this.match.url}countries`}
                load={() =>
                  import("../modules/content-managament/countries/Countries")
                }
              />
              <AppRoute
                path={`${this.match.url}currencies`}
                load={() =>
                  import("../modules/content-managament/currencies/Currencies")
                }
              />
              <AppRoute
                path={`${this.match.url}transaction-categories`}
                load={() =>
                  import(
                    "../modules/content-managament/transaction-categories/TransactionCategories"
                  )
                }
              />
              <AppRoute
                path={`${this.match.url}merchant-category-code`}
                load={() =>
                  import(
                    "../modules/content-managament/merchant-category-code/MerchantCategoryCode"
                  )
                }
              />
              <AppRoute
                path={`${this.match.url}transaction-merchant`}
                load={() =>
                  import(
                    "../modules/content-managament/transaction-merchant/TransactionMerchant"
                  )
                }
              />
              <AppRoute
                path={`${this.match.url}tiers`}
                load={() => import("../modules/tiers-fees/TiersFees")}
              />
              <AppRoute
                path={`${this.match.url}privacy`}
                load={() => import("../modules/privacy/Privacy")}
              />
              <AppRoute
                path={`${this.match.url}tax`}
                load={() => import("../modules/tax/TaxPage")}
              />
              <AppRoute
                path={`${this.match.url}shipping`}
                load={() => import("../modules/shipping/ShippingPage")}
              />
              <AppRoute
                path={`${this.match.url}analytics`}
                load={() => import("../modules/analytics/AnalyticsPage")}
              />
              <AppRoute
                path={`${this.match.url}transaction`}
                load={() => import("../modules/transactions/Transactions")}
              />
              <AppRoute
                path={`${this.match.url}card-management`}
                load={() =>
                  import("../modules/card-management/CardManagementPage")
                }
              />
              <AppRoute
                path={`${this.match.url}users`}
                load={() => import("../modules/users/UsersPage")}
              />
              <AppRoute
                path={`${this.match.url}personal-managers`}
                load={() =>
                  import("../modules/personal-managers/PersonalManagersPage")
                }
              />
              <AppRoute
                path={`${this.match.url}product-management`}
                load={() =>
                  import("../modules/product-management/ProductManagementPage")
                }
              />
              <AppRoute
                path={`${this.match.url}orders`}
                load={() => import("../modules/orders/OrdersPage")}
              />
              <AppRoute
                path={`${this.match.url}search`}
                load={() => import("../modules/search/SearchPage")}
              />
              <AppRoute
                path={`${this.match.url}kyc`}
                load={() => import("../modules/kyc/KYC")}
              />
              <AppRoute
                path={`${this.match.url}faq`}
                load={() => import("../modules/faq/Faq")}
              />
              <AppRoute
                path={`${this.match.url}events`}
                load={() => import("../modules/events/Events")}
              />
              <AppRoute
                path={`${this.match.url}chat`}
                load={() => import("../modules/chat/Chat")}
              />
              <AppRoute
                path={`${this.match.url}shop`}
                load={() => import("../modules/shop/ShopPage")}
              />
              <AppRoute
                path={`${this.match.url}push-notifications/`}
                component={PushNotificationsPage}
              />
              <AppRoute
                path={`${this.match.url}config`}
                load={() => import("../modules/config/Config")}
              />
              <AppRoute
                path={`${this.match.url}payments`}
                load={() => import("../modules/payments/Payments")}
              />

              <AppRoute
                path={`${this.match.url}bulk-issue`}
                load={() => import("../modules/bulk-issue/BulkIssue")}
              />
              <AppRoute
                exact
                path={`${this.match.url}`}
                component={DashboardPage}
              />
            </Switch>
          </div>

          <div className="clear"></div>
        </div>
      </div>
    );
  }
}
