import React from "react";
import moment from "moment";
import AppActiveSign from "AdminApp/common/script/components/ui/AppActiveSign";
import AppInfoList from "AdminApp/layouts/AppInfoList";

export default class GroupInfo extends React.Component {
  render() {
    let { group } = this.props;

    let details = [
      {
        label: "Name",
        value: group.name,
      },
      {
        label: "Created",
        value: moment(group.created).format("DD/MM/YYYY HH:mm:ss"),
      },
      // {
      //   label: "User count",
      //   value: group.userCount || 0,
      // },
      {
        label: "Is test group",
        value: <AppActiveSign value={group.isTestGroup} />,
      },
    ];

    return <AppInfoList items={details} />;
  }
}
