import URL from "url-parse";

import Config from "./config";

let env =
  process.env.REACT_APP_ENVIRONMENT ||
  Config.env ||
  (!process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "dev"
    : "live");

let publicUrl = process.env.REACT_APP_API_URL
  ? new URL(process.env.REACT_APP_API_URL).origin
  : new URL(Config[env].url).origin;

let common = {
  resetDB: false,
  publicUrl: publicUrl,
  passcodeLength: 6,
  cardRatio: 1.56,
  physicalCardCost: 7.5,
  disablePasscode: true,
  maxTopUpValue: 250,
  minTopUpValue: 10,
  initFilterValue: "***",
};

let configuration = {
  local: {
    ...common,
    checkout_url: "https://sandbox.checkout.com/api2/v2/tokens/card",
    checkout_public_key: "pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d",
    ...Config.local,
  },
  dev: {
    ...common,
    checkout_url: "https://sandbox.checkout.com/api2/v2/tokens/card",
    checkout_public_key: "pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d",
    ...Config.dev,
  },
  stage: {
    ...common,
    checkout_url: "https://sandbox.checkout.com/api2/v2/tokens/card",
    checkout_public_key: "pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d",
    ...Config.stage,
  },
  live: {
    ...common,
    checkout_url: "https://sandbox.checkout.com/api2/v2/tokens/card",
    checkout_public_key: "pk_test_24166a6e-18f7-4405-b42a-d74aa75dc05d",
    ...Config.live,
  },
};

const config = {
  env,

  // Preloaded configuration
  ...configuration[env],
};

export default config;
