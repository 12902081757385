import React, { Component } from "react";
import { Link } from "react-router-dom";
import NotificationUserGroupModel from "../models/NotificationUserGroupModel";
import AppTable from "AdminApp/components/crud/AppTable";
import DeleteButton from "AdminApp/elements/list/DeleteButton";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};
class UserGroupsList extends Component {
  _delete(id) {
    new NotificationUserGroupModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
        });
      });
  }

  render() {
    let disabledActionUser =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_ADMIN_TESTER");
    let disabledClass = disabledActionUser ? " disabled-btn" : "";

    return (
      <div className="inner-single-col">
        <div className="customer-groups">
          <h1>
            <i className="fa fa-users" aria-hidden="true"></i> User Groups
          </h1>
          <AppTable
            title="User Groups"
            onInit={(tableComponent) => (this._tableComponent = tableComponent)}
            headerActions={
              <Link
                to="/push-notifications/user-groups/add"
                className={"button width-240" + disabledClass}
                style={{ marginRight: "10px" }}
              >
                <i className="fa fa-fw fa-plus"></i>Add new
              </Link>
            }
            filter={[
              {
                name: "term",
                type: "term",
              },
            ]}
            order={[
              {
                field: "id",
                direction: "desc",
              },
            ]}
            model={NotificationUserGroupModel}
            renderCell={(column, item, index) => {
              switch (column.name) {
                case "kycStatus":
                  return this._renderStatus(item);
              }
            }}
            getColumnActions={(userGroup, index) => {
              return (
                <div>
                  <Link
                    to={`/push-notifications/user-groups/details/${userGroup.id}`}
                    className="button-info"
                  >
                    Details
                  </Link>
                  <Link
                    to={`/push-notifications/user-groups/edit/${userGroup.id}`}
                    className={"button-info" + disabledClass}
                  >
                    Edit
                  </Link>
                  <DeleteButton
                    disabled={disabledActionUser}
                    onConfirm={() => this._delete(userGroup.id)}
                    text={
                      'Are you sure you wish to delete user group "' +
                      userGroup.name +
                      '"?'
                    }
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps)(UserGroupsList);
