import React, { Component } from "react";
import SideMenuItem from "./SideMenuItem";
import $ from "jquery";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};

export class CustomerCareAdminNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigation: this.getNavigation(props),
    };
  }

  closeSideMenu() {
    $(".dashboard-side").addClass("collapsed");
    $(".dashboard-main").addClass("full-width");
    $(".dashboard-menu-top").addClass("fullsize");
    $(".breadcrumb").addClass("small");
    $(".collapse-menu").hide();
    $("#collapse").show();
    $(".sub-nav").css({ display: "none" });
    $(".sideMenuList li a span").css({ display: "none" });
    $("#collapse").css({ position: "relative", height: "40px" });
    $(".sub-nav").addClass("toggled-menu");
    $(".arrow").hide();
    $(".dashboard-main ").click(function () {
      $(".toggled-menu").css({ display: "none" });
    });
    $(".toggled-menu li").click(function () {
      $(".toggled-menu").css({ display: "none" });
    });
    $(".arrow").removeClass("arrow-down");
    // let sideMenu = function () {
    //   let el = $('.sideMenuList li');
    //   el.hover(function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').addClass('visible');
    //   }, function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').removeClass('visible');
    //   });
    // }
    // sideMenu();
    window.localStorage.setItem("IS_SIDE_MENU_OPEN", "false");
  }

  showSideMenu() {
    $(".dashboard-side").removeClass("collapsed");
    $(".dashboard-main").removeClass("full-width");
    $(".dashboard-menu-top").removeClass("fullsize");
    $(".breadcrumb").removeClass("small");
    $(".collapse-menu").show();
    $("#collapse").hide();
    $(".arrow").show();
    $(".arrow").removeClass("arrow-down");
    $(".sub-nav").css({ display: "none" });
    $(".sub-nav").removeClass("toggled-menu");
    $(".sideMenuList li a span").css({ display: "inline-block" });
    // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    window.localStorage.setItem("IS_SIDE_MENU_OPEN", "true");
  }

  componentDidMount() {
    // this.closeSideMenu();
    let isSideMenuOpen = window.localStorage.getItem("IS_SIDE_MENU_OPEN");

    if (isSideMenuOpen === "false") {
      this.closeSideMenu();
    }
    // if ($(window).width() < 1440) {
    //   this.closeSideMenu();
    //   $("#collapse").css({ display: "block", "margin-top": "12px" });
    //   $(".arrow").hide();
    // }
    if ($(".dashboard-side").hasClass("collapsed")) {
      $(".arrow").hide();
    } else {
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    }

    let toggleMenu = function () {
      let el = $(".sideMenuList").find("> li").find("> a");
      el.click(function () {
        let elm = $(this);
        let parentLi = elm.closest("li");

        parentLi
          .closest("ul")
          .children()
          .not(parentLi)
          .each(function (index, li) {
            let $li = $(li);
            $li.find(".sub-nav").slideUp();
            $li.find(".arrow").removeClass("arrow-down");
          });

        // console.error(parentLi);

        elm.parent().find(".sub-nav").slideToggle();
        elm.parent().find(".arrow").toggleClass("arrow-down");
      });
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    };
    toggleMenu();
  }

  render() {
    const navigation = this.getNavigation(this.props);
    let items = [];
    navigation.forEach((item, index) => {
      let key = "side-menu-item-" + index;
      items.push(<SideMenuItem {...item} key={key} />);
    });
    return (
      <div className="side-menu">
        <i
          id="collapse"
          onClick={this.showSideMenu}
          style={{
            color: "#fff",
          }}
          className="fa fa-bars"
          aria-hidden="true"
        ></i>

        <p className="collapse-menu" onClick={this.closeSideMenu}>
          <i
            style={{
              color: "#fff",
            }}
            className="fa fa-angle-double-left"
            aria-hidden="true"
          ></i>
          Collapse menu
        </p>

        <ul className="sideMenuList">{items}</ul>
      </div>
    );
  }

  getNavigation(props) {
    const navigation = [
      {
        label: "Dashboard",
        icon: "tachometer",
        path: "/",
      },
      {
        label: "KYC",
        icon: "check",
        path: null,
        children: [
          // {
          //   label: 'Dashboard',
          //   icon: 'tachometer',
          //   path: '/kyc'
          // },
          {
            label: "Requests",
            icon: "id-card-o",
            path: "/kyc/requests",
          },
        ],
      },
      {
        label: "Users",
        icon: "users",
        path: null,
        children: [
          {
            label: "All Users",
            path: "/users/all-users",
            icon: "users",
          },
          {
            label: "Secondary Users",
            path: "/users/secondary-users",
            icon: "users",
          },
          {
            label: " User Devices",
            path: "/users/user-devices",
            icon: "laptop",
          },
        ],
      },
      {
        label: "Payment devices",
        icon: "credit-card",
        path: "/cards",
      },
      {
        label: "Transactions",
        icon: "exchange",
        path: "/transaction",
      },
      {
        label: "Payments",
        icon: "money",
        path: null,
        children: [
          {
            label: "Incoming payments",
            icon: "arrow-down",
            path: "/payments/incoming",
          },
          {
            label: "Outgoing payments",
            icon: "arrow-up",
            path: "/payments/outgoing",
          },
          {
            label: "P2P payments",
            icon: "exchange",
            path: "/payments/p2p",
          },
          {
            label: "External payment requests",
            icon: "external-link",
            path: "/payments/external-payment-requests",
          },
        ],
      },
      {
        label: "Reports",
        icon: "file",
        path: null,
        children: [
          {
            label: "Totals Devices",
            path: "/reports/totals",
            icon: "file-text-o",
          },
          {
            label: "Devices",
            path: "/reports/cards",
            icon: "credit-card",
          },
          {
            label: "Totals Accounts",
            path: "/reports/totals-accounts",
            icon: "file-text-o",
          },
        ],
      },
      {
        label: "Events",
        icon: "file-text-o",
        path: "/events",
        children: [],
      },
      {
        label: "Notifications",
        icon: "bell",
        // path: '/push-notifications',
        children: [
          {
            label: "Push dashboard",
            path: "/push-notifications",
            icon: "dashboard",
          },
          {
            label: "Send new notification",
            path: "/push-notifications/add-new",
            icon: "plus",
          },
          {
            label: "Sent notifications",
            path: "/push-notifications/sent-notifications",
            icon: "paper-plane",
          },
          {
            label: "User Groups",
            path: "/push-notifications/user-groups",
            icon: "users",
          },
        ],
      },
      {
        label: "Shop",
        icon: "shopping-basket",
        path: null,
        children: [
          {
            label: "Purchased products",
            path: "/shop/purchase-products",
            icon: "cart-arrow-down",
          },
        ],
      },
    ];

    if (props.profile && props.profile.isPersonalManager) {
      navigation.push({
        label: "Chat",
        icon: "comments-o",
        path: "/chat",
        totalChatUnreadMessagesIndicator: true,
      });
    }

    return navigation;
  }
}

export default connect(mapStateToProps)(CustomerCareAdminNavigation);
