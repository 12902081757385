import React, { Component } from "react";
import { Link } from "react-router-dom";
import AppTable from "AdminApp/components/crud/AppTable";
import SendNotificationModel from "./models/SendNotificationModel";
import AppBadge from "AdminApp/components/typography/AppBadge";
import DateHelper from "AdminApp/logic/helpers/DateHelper";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};
class SentNotifications extends Component {
  render() {
    let disabledActionUser =
      this.props.profile &&
      this.props.profile.roles.includes("ROLE_ADMIN_TESTER");
    let disabledClass = disabledActionUser ? " disabled-btn" : "";
    return (
      <div className="inner-single-col">
        <div className="customer-groups">
          <h1>
            <i className="fa fa-paper-plane" aria-hidden="true"></i> Sent
            Notifications
          </h1>
          <AppTable
            title="Sent notifications"
            onInit={(tableComponent) => (this._tableComponent = tableComponent)}
            headerActions={
              <Link
                to="/push-notifications/add-new"
                className={"button width-240" + disabledClass}
                style={{ marginRight: "10px" }}
              >
                <i className="fa fa-fw fa-plus"></i>Send new
              </Link>
            }
            filter={[
              {
                name: "term",
                type: "term",
              },
            ]}
            order={[
              {
                field: "id",
                direction: "desc",
              },
            ]}
            model={SendNotificationModel}
            renderCell={(column, item, index) => {
              switch (column.name) {
                case "status":
                  return <AppBadge text={item.status} />;
                case "created":
                  return DateHelper.date(item.created, "D / MMMM / Y");
                case "text":
                  return (
                    <p
                      style={{
                        whiteSpace: "nowrap",
                        width: 200,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.text}
                    </p>
                  );
                default:
                  return undefined;
              }
            }}
            getColumnActions={(sendNotification, index) => {
              return (
                <div>
                  <Link
                    to={`/push-notifications/details/${sendNotification.id}`}
                    className="button-info"
                  >
                    Details
                  </Link>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(SentNotifications);
