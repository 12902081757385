import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: "white",
    "&$checked": {
      color: "#4fd615",
    },
  },
  label: {
    color: "white",
  },
  checked: {},
};

class AppCheckboxInput extends Component {
  state = {
    value: false,
  };

  /**
   * Toggle checkbox value
   */

  handleChange = (name) => (event) => {
    this.setState(
      {
        [name]: event.target.checked,
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(
            this.props.name,
            this.state.value
          );
        }

        if (this.props.onChange) {
          this.props.onChange(this.state.value);
        }
      }
    );
  };

  render() {
    const { classes, style } = this.props;

    return (
      <FormControlLabel
        style={{
          width: "100%",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          ...style,
        }}
        classes={{
          label: classes.label,
        }}
        control={
          <Checkbox
            checked={this.props.value}
            onChange={this.handleChange("value")}
            value="value"
            classes={{
              root: classes.root,
              checked: classes.checked,
            }}
          />
        }
        label={this.props.label}
      />
    );
  }
}

AppCheckboxInput.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AppCheckboxInput);
