import BaseModel from "AdminApp/common/script/models/BaseModel";

export default class NotificationUserModel extends BaseModel {
  getListUrl() {
    return "notifications/send-request/users";
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: "id",
        label: "Id",
        sortable: true,
        type: "field",
      },
      {
        name: "user.fullName",
        label: "Full name",
        sortable: true,
        type: "field",
      },
      {
        name: "user.email",
        label: "E-mail",
        sortable: false,
        type: "field",
      },
    ];
  }
}
