import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logoutTriggerAction } from "AdminApp/modules/auth/AuthActions";
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedOut: (token) => {
      return dispatch(logoutTriggerAction(token));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    profile: state.auth.profile,
  };
};

export class TopBar extends Component {
  constructor() {
    super();
    this.state = {
      menuOpened: false,
      notificatonsOpened: false,
    };
  }

  showMenu() {
    this.setState({
      menuOpened: true,
    });
  }

  hideMenu() {
    this.setState({
      menuOpened: false,
    });
  }
  showNotifications() {
    this.setState({
      notificatonsOpened: true,
    });
  }

  hideNotifications() {
    this.setState({
      notificatonsOpened: false,
    });
  }

  getElementStyle() {
    let style = {
      opacity: "0",
      top: "54px",
    };
    if (this.state.menuOpened) {
      style.opacity = "1";
      style.top = "38px";
    }
    return style;
  }
  getLinkStyle() {
    let linkStyle = {
      display: "none",
    };
    if (this.state.menuOpened) {
      linkStyle.display = "block";
    }
    return linkStyle;
  }
  getNotifications() {
    let notificatonStyle = {
      opacity: "1",
      display: "none",
    };
    if (this.state.notificatonsOpened) {
      notificatonStyle.opacity = "1";
      notificatonStyle.display = "block";
    }
    return notificatonStyle;
  }
  notifications() {
    var x = document.getElementById("notif");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
  logoutUser(token) {
    this.props.userLoggedOut(token);
    window.localStorage.setItem("IS_SIDE_MENU_OPEN", "false");
  }
  render() {
    let style = this.getElementStyle();
    let linkStyle = this.getLinkStyle();
    let notificatonStyle = this.getNotifications();

    return (
      <div
        className="dashboard-menu-top"
        onMouseLeave={this.hideMenu.bind(this)}
      >
        <div
          className="dark-blur"
          onMouseLeave={this.hideNotifications.bind(this)}
        ></div>
        <p className="left">BANKVAULT</p>
        {/* <div id="notificatons-holder" style={{}}>
          <a href="javascript:void(0)" onClick={this.notifications.bind(this)} ><i className="fa fa-bell" aria-hidden="true" style={{ color: '#fff' }}></i><i className="badge badge-danger">1</i></a>
          <div style={{ position: 'relative' }}>
            <div id="notif" className="notifications" style={notificatonStyle}>
              <div className="dark-blur"></div>
              <ul >
                <li>
                  <div className="notification-text">Customer <Link to="/customers/details" onClick={this.notifications.bind(this)}>Hilton</Link> skipped order this July</div>
                  <div className="notification-actions">
                    <Link to="/customers/details" onClick={this.notifications.bind(this)}>details</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* <div className="search">
          <form>
            <div className="form-group">
              <input type="text" name="search" placeholder="Search..." />
            </div>
          </form>
        </div> */}
        <a className="right" onMouseEnter={this.showMenu.bind(this)}>
          <i className="fa fa-cogs" aria-hidden="true"></i>{" "}
          {this.props.profile && this.props.profile.fullName
            ? this.props.profile.fullName
            : "Superadmin"}
        </a>
        <div className="superadmin-dropdown" style={style}>
          <div className="dark-blur"></div>
          <div>
            {/* <a href="#" style={linkStyle}><i className="fa fa-user" aria-hidden="true"></i> My account</a> */}
            <a
              href="javascript:void(0)"
              onClick={(token) => this.logoutUser(token)}
              style={linkStyle}
            >
              <i className="fa fa-eject" aria-hidden="true"></i> Log out
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
