import React, { Component } from "react";
import ReactModal from "react-modal";

export default class ModalDialog extends Component {
  constructor() {
    super();
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentWillMount() {
    ReactModal.setAppElement("body");
  }

  handleCloseModal() {
    this.props.onClose();
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.show}
        onRequestClose={this.handleCloseModal}
        className={"react-modal-content " + this.props.customContentStyle}
        overlayClassName="react-modal-overlay"
        portalClassName="react-modal-portal"
        shouldReturnFocusAfterClose={true}
        role="dialog"
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
        shouldCloseOnEsc={this.props.shouldCloseOnEsc}
        parentSelector={() => document.body}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}
ModalDialog.defaultProps = {
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
};
