import React from "react";
import BaseComponent from "../..//components/BaseComponent";
import TextField from "@material-ui/core/TextField";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: "rgba(255, 255, 255, 1)" },
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "1px solid #ffffff!important",
        },
        "&:after": {
          borderBottom: "2px solid #0275d8!important",
        },
      },
      multiline: {
        color: "white",
      },
      root: {
        // Name of the rule
        color: "white", // Some CSS
        minHeight: 36,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "white !important",
      },
      root: {
        color: "white",
        borderColor: "white",
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "white",
        },
        minHeight: 36,
      },
    },
    MuiFormLabel: {
      root: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
  },
});
export default class InputField extends BaseComponent {
  constructor() {
    super();
    this.state = {};
  }
  /**
   * Get class name
   *
   * @returns {string}
   */
  getClassName() {
    let classNames = ["input-group"];
    if (this.props.fullWidth) {
      classNames.push("fulled");
    }

    return classNames.join(" ");
  }

  /**
   * Handle input change
   *
   * @param e
   */
  handleInputChange(e) {
    if (this.props.amountInput) {
      // max length of numbers without dot or comma and 2 decimals
      let maxLength = this.props.maxLength || 10;
      // Allow backspace and delete keys
      if (e.key === "Backspace" || e.key === "Delete") return;

      // Allow only one dot or comma in the input
      if ((e.key === "." || e.key === ",") && this.value.includes(e.key)) {
        e.preventDefault();
      }
      // Regular expression to allow digits with optional dot or comma and at most 2 decimals
      const regex = new RegExp(`^\\d{0,${maxLength}}([.,]\\d{0,2})?$`);

      // Get the input value
      let inputValue = e.target.value;

      // Test if the input value matches the regular expression
      if (regex.test(inputValue)) {
        // If it matches, set the input value
        e.target.dataset.previousValue = inputValue;
      } else {
        // If it doesn't match, restore the previous value
        e.target.value = e.target.dataset.previousValue || "";
      }
    }

    if (this.props.handler) {
      this.props.handler.handleInputChange(e);
    } else {
      this.props.onChange(e);
    }
  }

  getValidationError() {
    let messages = [];

    if (!this.props.handler) return null;

    this.props.handler
      .getErrors()
      .filter((x) => x.field === this.props.name)
      .forEach((error) => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div className="validation-message" key={this.props.name + "-error"}>
        {messages.join("<br/>")}
      </div>
    );
  }

  /**
   * Render input
   *
   * @returns {XML}
   */
  render() {
    let className = this.getClassName();
    let validationError = this.getValidationError();

    return (
      <div className={className}>
        <MuiThemeProvider theme={theme}>
          <TextField
            ref="actualInput"
            autoComplete={this.props.autoComplete}
            label={this.props.label}
            type={this.props.type}
            name={this.props.name}
            inputProps={this.props.inputProps}
            value={this.props.value}
            onChange={this.handleInputChange.bind(this)}
            onWheel={(event) => {
              if (this.props.type === "number") {
                event.target.blur();
              }
            }}
            {...this.props.materialProps}
            variant={this.props.type === "textarea" ? "outlined" : "standard"}
            InputProps={
              ({ ...this.props.InputProps },
              this.props.prefix
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        {this.props.prefix}
                      </InputAdornment>
                    ),
                  }
                : null)
            }
          />
        </MuiThemeProvider>
        {validationError}
      </div>
    );
  }
}
