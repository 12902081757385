import React from "react";
import Api from "../Api";
import LoadingOverlay from "../../components/LoadingOverlay";
import validation from "validate.js";
import _ from "lodash";

export default class FormHandler {
  /**
   * Constructor
   *
   * @param component
   * @param startingData
   */
  constructor(component, model) {
    this.model = model;
    this.component = component;
    this.callbacks = {
      submitError: [],
      submitSuccess: [],
    };

    let startingData = model.getData();
    this.startingData = startingData;

    // Set default component state
    component.state = {
      isLoading: false,
      submitStatus: false,
      record: startingData,
      errors: [],
    };

    // Rewire componentWillUpdate
    let oldWillUpdate = component.componentWillUpdate;
    component.componentWillUpdate = (nextProps, nextState) => {
      this.state = nextState;
      if (typeof oldWillUpdate == "function") {
        oldWillUpdate(nextProps, nextState);
      }
    };

    // Store state here
    this.state = component.state;

    // Map setState from component
    this.setState = (param) => {
      component.setState(param, () => {
        this.state = component.state;
      });
    };
  }

  reset() {
    this.setState({
      record: this.startingData,
    });
  }

  /**
   * Get submit url
   *
   * @returns {null}
   */
  getSubmitUrl() {
    return this.model.getSubmitUrl();
  }

  /**
   * Handle submit
   *
   * @param event
   * @returns {boolean}
   */
  handleSubmit(event = null) {
    return new Promise((resolve, reject) => {
      if (event) {
        event.preventDefault();
      }

      // Clear errors
      this.clearErrors();

      // Client side validation
      if (!this.clientValidation()) {
        return;
      }

      // On before submit
      if (this.onBeforeSubmit() === false) {
        reject();
        return;
      }
      this.onBeforeSubmit();

      // Call api to login
      Api.call(this.getSubmitUrl(), this.getSubmitData())
        .then(
          (data) => {
            this.onSubmitSuccess(data);
            resolve(data);
          },
          (error) => {
            this.onSubmitError(error);
            // reject(error);
          }
        )
        .finally(() => {
          this.onAfterSubmit();
        });
    });
  }

  /**
   * Called before submit
   */
  onBeforeSubmit() {
    // Set loading
    this.setState({
      isLoading: true,
    });
  }

  /**
   * Called after submit
   */
  onAfterSubmit() {
    this.setState({
      isLoading: false,
    });
  }

  /**
   * Returns submit data to send over AJAX
   *
   * @returns {{}|record|*}
   */
  getSubmitData() {
    return this.model?.getSubmitData
      ? this.model.getSubmitData(this.state.record)
      : this.state.record;
  }

  /**
   * Called on ajax success
   *
   * @param data
   */
  onSubmitSuccess(data) {
    // Parse response
    let submitStatus = false;
    if (data.success) {
      submitStatus = true;
      if (data.data && data.data.redirect) {
        window.location = data.data.redirect;
      }
    }
    // Set state
    this.setState({
      submitStatus: submitStatus,
      submitMessage: data.message,
    });

    if (data.success) {
      this.callback("submitSuccess", data);
      if (this.component.onSubmitSuccess) {
        this.component.onSubmitSuccess(data);
      }
    }

    if (!data.success) {
      this.callback("submitError", data);
      if (this.component.onSubmitError) {
        this.component.onSubmitError(data);
      }
    }

    // Clear state
    setTimeout(() => {
      this.setState({
        submitMessage: null,
      });
    }, 3000);
  }

  /**
   * Called on submit error
   *
   * @param error
   */
  onSubmitError(error) {
    // console.error('error', error);
    if (error.errors && error.errors.length) {
      this.setState({
        submitStatus: false,
        submitMessage: error.errors[0].message,
      });
    }

    if (this.component.onSubmitError) {
      this.component.onSubmitError(error);
    }
    this.callback("submitError", error);
  }

  /**
   * Handle input change
   *
   * @param event
   */
  handleInputChange(event, passedValue = null) {
    let name = null;
    let value = null;
    if (!event.target) {
      name = event;
      value = passedValue;
    } else {
      name = event.target.name;
      value = event.target.value;
    }

    let newRecord = { ...this.state.record };
    newRecord = _.set(newRecord, name, value);
    this.setState({
      ...this.state,
      record: newRecord,
    });

    // this.setState({
    //   ...this.state,
    //   record: {
    //     ...this.state.record,
    //     [name]: value,
    //   },
    // });

    if (passedValue !== "") {
      this.clearErrors();
    }
  }

  /**
   * Get submission status component
   *
   * @returns {string}
   */
  getSubmissionStatus() {
    let submissionStatus = "";

    if (this.state.submitMessage) {
      submissionStatus = (
        <div className="form-message">{this.state.submitMessage}</div>
      );
    }

    return submissionStatus;
  }

  /**
   * Get form class component
   *
   * @returns {string}
   */
  getFormClass() {
    let formClass = "";
    if (typeof this.state.submitStatus !== "undefined") {
      formClass = this.state.submitStatus ? "" : "form-error";
    }

    return formClass;
  }

  /**
   * Get loading component
   *
   * @returns {string}
   */
  getLoadingComponent() {
    let loadingComponent = "";
    if (this.state.isLoading) {
      loadingComponent = <LoadingOverlay />;
    }

    return loadingComponent;
  }

  /**
   * Invalidate field
   *
   * @param field
   * @param message
   */
  invalidateField(field, message) {
    let errors = this.state.errors || [];

    errors.push({
      field: field,
      message: message,
    });

    this.setState({
      errors: errors,
    });
  }

  clearErrors() {
    this.setState({
      errors: [],
    });
    this.state.errors = [];
  }

  getErrors() {
    return this.state.errors;
  }
  validate() {
    this.clearErrors();
    return this.clientValidation();
  }

  clientValidation() {
    let validationRules = this.model.getValidationRules();

    let data = { ...this.state.record };

    for (var k in data) {
      let value = data[k];
      if (typeof value == "string" && value.trim() === "") {
        data[k] = null;
      }
    }

    let errors = validation.validate(data, validationRules, {
      fullMessages: false,
    });

    for (var field in errors) {
      this.invalidateField(field, errors[field]);
    }

    let customErrors = this.model.customValidation(this.state.record);

    if (customErrors.length) {
      customErrors.forEach((error) => {
        this.invalidateField(error.field, error.message);
      });
    }

    return this.getErrors().length === 0;
  }

  on(callbackName, callbackFunction) {
    if (!this.callbacks[callbackName]) {
      this.callbacks[callbackName] = [];
    }

    this.callbacks[callbackName].push(callbackFunction);
  }

  callback(callbackName, data) {
    if (!this.callbacks[callbackName]) return;

    let callbacks = this.callbacks[callbackName];
    for (let i = 0; i < callbacks.length; i++) {
      try {
        callbacks[i](data);
      } catch (e) {
        console.error(e);
      }
    }
  }
}
