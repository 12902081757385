import React from "react";
import AppIcon from "../components/typography/AppIcon";

export default class AppPage extends React.Component {
  render() {
    return (
      <div className="dashboard-management">
        <h1>
          <AppIcon icon={this.props.icon} /> {this.props.title}
        </h1>
        {this.props.children}
      </div>
    );
  }
}

AppPage.defaultProps = {
  title: "Page Title",
  icon: null,
};
