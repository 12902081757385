import React, { Component } from "react";
import FormHandler from "AdminApp/common/script/services/crud/FormHandler";
import InputField from "AdminApp/common/script/components/ui/InputField";
import { Link, Redirect } from "react-router-dom";
import { loginTriggerAction } from "AdminApp/modules/auth/AuthActions";
import { connect } from "react-redux";
import LoginOTPModel from "AdminApp/common/script/models/security/LoginOTPModel";
import FormSubmitWrapper from "AdminApp/common/script/components/crud/FormSubmitWrapper";

class OTPPage extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new LoginOTPModel({
        code: "",
        hash: "",
      }),
      {
        redirect: false,
        otp: {},
      }
    );
  }

  componentDidMount() {
    const { otp } = this.props.location.state;

    if (!otp) {
      this.setState({
        redirect: true,
      });
      return;
    }

    this.setState({
      record: {
        ...this.state.record,
        code: "",
        hash: otp.hash,
      },
      otp: otp,
    });
  }

  onSubmitSuccess(data) {
    if (data.success && data.data.token) {
      this.props.userLoggedIn(data.data.token, data.data.account);
      setTimeout(() => {
        this.setState({
          redirect: true,
        });
      }, 3000);
    }
  }
  _getModalMessage(data) {
    if (!data.success) {
      return data.message;
    }

    if (data.success && !data.message) {
      return "Authentication successful!";
    }
  }

  _getMesage() {
    const { otp } = this.props.location.state;

    switch (otp.handler) {
      case "email":
        return "We sent an email with the verification code to your email address. Please enter it below";
      case "sms":
        return "We sent an SMS with the verification code to your phone number. Please enter it below";
    }

    return "Please enter the code below";
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/"></Redirect>;
    }
    let formHandler = this.formHandler;

    let message = this._getMesage();
    return (
      <div>
        <div className="container-fluid login-page">
          <img
            className="logo"
            src={require("AdminApp/assets/img/logo.svg")}
            alt="logo"
          />
          <h1>
            Welcome to Bourgeois Bohème{" "}
            <span style={{ display: "block", textAlign: "center" }}>
              Administration
            </span>
          </h1>
          <div className="login-box" style={{ width: "100%" }}>
            <div className="white-blur"></div>
            <div className="inner" style={{ padding: 40 }}>
              <div>
                <h2 style={{ textTransform: "uppercase" }}>2FA Verification</h2>
                <div style={{ textAlign: "center" }}>
                  <span>{message}</span>
                </div>
                <FormSubmitWrapper
                  showBtn={false}
                  formHandler={formHandler}
                  to="/"
                  buttonText="SUBMIT"
                  getModalMessage={(data) => this._getModalMessage(data)}
                >
                  <InputField
                    classes={"loginInput"}
                    materialProps={{
                      fullWidth: true,
                    }}
                    label="Code"
                    name="code"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.code}
                    handler={formHandler}
                  />

                  <span className="text-center">
                    {formHandler.getSubmissionStatus()}
                  </span>

                  <Link to="/login" className="back-button">
                    Back
                  </Link>
                </FormSubmitWrapper>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <p>©{new Date().getFullYear()} Pannovate. All rights reserved.</p>
          <p>European Patent Application No. 14704638.7</p>
        </footer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLoggedIn: (token, account) => {
      return dispatch(loginTriggerAction(token, account));
    },
  };
};

export default connect(null, mapDispatchToProps)(OTPPage);
