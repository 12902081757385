import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginLayout from "./layouts/LoginLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import { AppRoute, AppRouter } from "AdminApp/components";
import { connect } from "react-redux";
import { appMountedAction } from "AdminApp/logic/store/AppActions";
import { refreshAuthAction } from "AdminApp/modules/auth/AuthActions";

import ForgotPassword from "AdminApp/modules/login/ForgotPassword";

// Set chart color
import Chart from "chart.js";
import OTPPage from "AdminApp/modules/login/OTPPage";
Chart.defaults.global.defaultFontColor = "white";
//Chart.defaults.global.fullWidth = true;

const mapDispatchToProps = (dispatch) => {
  return {
    appMounted: () => {
      return dispatch(appMountedAction());
    },
    refreshAuth: () => {
      return dispatch(refreshAuthAction());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    ready: state.ready,
  };
};

class App extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.props.appMounted();
    this.props.refreshAuth();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    if (!this.props.ready) return null;

    return (
      <AppRouter>
        {this.state.width < 768 ? (
          <div className="small-device-overlay">
            <p>
              Please note that this app is not configured for us on screens
              smaller than 11”. Please log in from an appropriate device and/or
              screen.
            </p>
          </div>
        ) : (
          <div>
            <Switch>
              <AppRoute
                path="/login/otp"
                component={OTPPage}
                unAuthenticatedOnly
              />
              <AppRoute
                path="/reset-password"
                component={ForgotPassword}
                unAuthenticatedOnly
              />
              <AppRoute
                path="/login"
                component={LoginLayout}
                unAuthenticatedOnly
              />
              <AppRoute path="/" component={DefaultLayout} authenticatedOnly />
            </Switch>
          </div>
        )}
      </AppRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
