import React from "react";
import InputField from "AdminApp/common/script/components/ui/InputField";
import _ from "lodash";
import UserModel from "../../../users/all-users/models/UserModel";
import LoadingOverlay from "AdminApp/common/script/components/LoadingOverlay";

export default class SelectSpecificUsers extends React.Component {
  /**
   * Constructor
   *
   * @param {object} props Props
   */
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    // console.log("props", props);
    this.state = {
      term: "",
      results: [],
      selectedIds: props.userIds ? props.userIds : [],
      selectedUsers: [],
      loading: false,
    };
  }

  /**
   * Call on component mount
   */
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this._pullSelectedUsers();
  }

  /**
   * Call on component unmount
   */
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Handle click outside the wrapper
   *
   * @param {object} event
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        term: "",
        results: [],
      });
    }
  }

  /**
   * Show filtered users
   */
  _showFilteredUsers() {
    let { results, loading } = this.state;

    if (loading) {
      return <LoadingOverlay style={{ position: "relative" }} />;
    }

    if ((!results || !results.length) && !loading) {
      return null;
    }

    return (
      <div
        className="filtered-users-holder"
        ref={(ref) => (this.wrapperRef = ref)}
      >
        <ul
          className="filtered-results"
          style={{ listStyleType: "none", paddingLeft: 0 }}
        >
          {results.map((user, index) => {
            return (
              <UserFilterItem
                user={user}
                index={index}
                selectedIds={this.state.selectedIds}
                onAdd={(id) => this._addUser(id)}
                onRemove={(id) => this._removeUser(id)}
              />
            );
          })}
        </ul>
      </div>
    );
  }

  /**
   * Show selected users
   */
  _showSelectedUsers() {
    let { selectedUsers } = this.state;

    if (!selectedUsers || !selectedUsers.length) {
      return <em>no users selected</em>;
    }

    return (
      <div className="selected-users-holder">
        <ul
          className="filtered-results"
          style={{ listStyleType: "none", paddingLeft: 0 }}
        >
          {selectedUsers.map((user, index) => {
            if (this.state.selectedIds.filter((x) => x == user.id).length == 0)
              return null;
            return (
              <UserFilterItem
                user={user}
                index={index}
                selectedIds={this.state.selectedIds}
                onAdd={(id) => this._addUser(id)}
                onRemove={(id) => this._removeUser(id)}
              />
            );
          })}
        </ul>
      </div>
    );
  }

  /**
   * Add user id
   *
   * @param {integer} userId User id
   */
  _addUser(userId) {
    if (!this.state.selectedIds.filter((x) => x == userId).length) {
      this.setState(
        {
          selectedIds: [...this.state.selectedIds, userId],
        },
        () => {
          this.props.onChange(this.state.selectedIds);
          this._pullSelectedUsers();
        }
      );
    }
  }

  /**
   * Remove user id
   *
   * @param {integer} userId User id
   */
  _removeUser(userId) {
    this.setState(
      {
        selectedIds: this.state.selectedIds.filter((x) => x != userId),
      },
      () => {
        this.props.onChange(this.state.selectedIds);
        this._pullSelectedUsers();
      }
    );
  }

  /**
   * Pull selected users
   */
  async _pullSelectedUsers() {
    let results = await new UserModel().fetch({
      limit: 10000,
      filter: [
        {
          name: "userIds",
          field: "id",
          value: this.state.selectedIds,
        },
      ],
    });

    this.setState({
      selectedUsers: results.items,
    });
  }

  /**
   * Changed search value - trigger filter
   *
   * @param {string} value Term
   */
  _changedSearch(value) {
    this.setState(
      {
        term: value,
      },
      () => {
        this._debounceFilter();
      }
    );
  }

  /**
   * Trigger filter
   */
  async _triggerFilter() {
    let term = this.state.term;
    this.setState({
      loading: true,
    });

    // Term limit is 3
    if (!term || term.length < 3) {
      this.setState({
        results: [],
        loading: false,
      });
      return;
    }

    // Fetch new users
    let result = await new UserModel().fetch({
      filter: [
        {
          name: "term",
          type: "term",
          value: this.state.term,
        },
      ],
      // limit: 1000000,
    });

    this.setState({
      results: result.items,
      loading: false,
    });
  }

  /**
   * Debounce filter function
   */
  _debounceFilter = _.debounce(() => this._triggerFilter(), 200);

  /**
   * Render
   */
  render() {
    return (
      <div className="search-users dark-well">
        <em>Search for users</em>
        <div className={"filter-section"}>
          <InputField
            materialProps={{
              fullWidth: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete={"off"}
            onChange={(e) => this._changedSearch(e.target.value)}
            label=""
            type="text"
            name="name"
            value={this.state.term}
          />
          {this._showFilteredUsers()}
        </div>
        {this._showSelectedUsers()}
      </div>
    );
  }
}

SelectSpecificUsers.defaultProps = {
  onChange: () => {},
};

class UserFilterItem extends React.Component {
  /**
   * Render user filter item
   */
  render() {
    let { index, user, selectedIds, onAdd, onRemove } = this.props;

    return (
      <li
        key={index}
        className="d-flex mb-2 align-items-center justify-content-between"
        style={{ borderBottom: "1px solid white" }}
      >
        <div className="user-info">
          <h5>{user.fullName}</h5>
          <span>E-mail: {user.email}</span>
          <br />
          <span>ID: {user.id}</span>
        </div>
        <div className="user-actions">
          {selectedIds.filter((x) => x == user.id).length == 0 ? (
            <a
              href="javascript:void(0)"
              className="button button-info"
              onClick={() => onAdd(user.id)}
            >
              Add
            </a>
          ) : (
            <a
              href="javascript:void(0)"
              className="button button-danger"
              onClick={() => onRemove(user.id)}
            >
              Remove
            </a>
          )}
        </div>
      </li>
    );
  }
}
