import React from "react";
import BaseComponent from "./BaseComponent";

export default class LoadingOverlay extends BaseComponent {
  render() {
    return (
      <div className="loading-overlay" style={this.props.style}>
        <img src={require("AdminApp/assets/oval.svg")} />
      </div>
    );
  }
}
