import moment from "moment";

export function date(dateValue, format = "DD/MM/YYYY") {
  return moment(dateValue).format(format);
}

export function dateTime(dateValue, format = "DD/MM/YYYY HH:mm:ss") {
  return moment(dateValue).format(format);
}

export function getHumanized(dateValue) {
  return date(dateValue, "ddd, MMM Do");
}

export function add(date, count, intervals) {
  if (date == null) {
    date = moment();
  }
  return moment(date).add(count, intervals);
}

export default {
  date,
  dateTime,
  getHumanized,
  add,
};
